import http from '~/http/index';

export default ({
  state: {
    data: [],
    filters: {
      searchString: '',
    },
  },

  getters: {
    requestData(state) {
      return state.data.filter((item) => {
        return (
          item.groupName.toLowerCase().includes(state.filters.searchString.toLowerCase())
          || item.words.toLowerCase().includes(state.filters.searchString.toLowerCase())
        );
      });
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_DATA(state, data) {
      state.data = data.map((item) => {
        return {
          ...item,
          words: item.words.join(', '),
        };
      });
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },

    async loadData({ commit }) {
      const response = await http.get('p2p/Admin/advertisements/moderation-words');
      const { data } = response;
      commit('SET_DATA', data);
    },

    async deleteWords(__store, { id }) {
      const response = await http.delete(`p2p/Admin/advertisements/moderation-words?id=${id}`);
      return response.data;
    },

    async editWords(__store, params) {
      const query = new URLSearchParams(params.stringFormat).toString();
      const res = params.words.map((item, index) => {
        if (!index) return `&words=${item}`;
        return `&words=${item}`;
      }).join('');
      const response = await http.put(`p2p/Admin/advertisements/moderation-words?${query}${res}`);
      return response.data;
    },

    async createWords(__store, data) {
      const query = new URLSearchParams(data).toString();
      const response = await http.post(`p2p/Admin/advertisements/moderation-words?${query}`);
      return response.data;
    },
  },

  namespaced: true,
});
