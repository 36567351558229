//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';
import items from '~/config/navigationMain';

export default {
  data() {
    return {
      items,
    };
  },

  computed: {
    ...mapState('common', ['showNavigation']),
    ...mapState('login', ['currentAdmin']),

    isOpen: {
      get() {
        const { showNavigation } = this;
        return showNavigation;
      },
      set(value) {
        this.setShowNavigation(value);
      },
    },

    status() {
      const { adminStatus } = this.currentAdmin;
      const { ADMIN_STATUS_LIST } = constant.admin;
      return this.$utils.getCaption(ADMIN_STATUS_LIST, adminStatus);
    },
  },

  methods: {
    ...mapActions('common', [
      'setShowNavigation',
      'confirmAction',
      'setGeneralProgress',
    ]),
    ...mapActions('login', {
      logoutAction: 'logout',
    }),

    isCurrentRoute(name) {
      const currentRouteName = this.$route.name;
      const currentRootRouteName = this.$route.meta.rootRouteName;
      return (name === currentRouteName) || (name === currentRootRouteName);
    },

    goToPage(routeName) {
      const isCurrentRoute = this.$route.name === routeName;
      if (isCurrentRoute) {
        return;
      }
      this.$router.push({ name: routeName });
    },

    logout() {
      this.confirmAction({
        title: 'Are you sure you want to logout?',
        callback: async () => {
          this.setGeneralProgress(true);
          await this.logoutAction();
          this.setGeneralProgress(false);
          this.$router.push({ name: 'login' });
        },
      });
    },
  },
};
