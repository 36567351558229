import http from '~/http/index';

export default ({
  state: {
    data: [],
    sort: {
      sort: 'sortId',
      sortDesc: false,
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        page: page - 1,
        limit: itemsPerPage,
      };
    },
  },

  mutations: {
    SET_SORT(state, data) { state.sort = data; },

    SET_DATA(state, data) {
      state.data = data;
    },
    UPDATE_DATA(state, data) {
      state.data = state.data.map((item) => {
        const findItem = data.find((x) => x.title === item.title);
        return {
          ...item,
          serviceFee: findItem ? findItem.serviceFee : 0,
          fees: findItem ? findItem.fees : [],
          currencyId: findItem ? findItem.id : 0,
        };
      });
    },
  },

  actions: {
    setSort({ commit }, data) { commit('SET_SORT', data); },

    async loadData({ commit, dispatch, getters }, data) {
      const response = await http.post('v2/Market/GetCurrencies', {
        all: true,
        sortDesc: true,
        ...getters.requestData,
        ...data,
      });
      const { items } = response.data;
      commit('SET_DATA', items);
      await dispatch('loadCurrenciesWithFees');
    },

    async loadCurrenciesWithFees({ commit }) {
      const response = await http.get('p2p/Admin/Currency');
      const { data } = response.data;
      commit('UPDATE_DATA', data);
    },

    async createCurrency(__store, data) {
      const response = await http.post('Market/CreateCurrency', data);
      return response.data;
    },

    async editCurrency(__store, data) {
      const response = await http.post('Market/EditCurrency', data);
      return response.data;
    },

    async setCurrencyIcon(__store, { params, data }) {
      const response = await http.put('Market/SetCurrencyIcon', data, {
        params,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    },

    async deleteCurrencyIcon(__store, params) {
      const response = await http.put('Market/DeleteCurrencyIcon', null, { params });
      return response.data;
    },

    async createFee(__store, data) {
      const response = await http.post('p2p/Admin/Currency/fee', data);
      return response.data;
    },

    async editFee(__store, data) {
      const response = await http.put('p2p/Admin/Currency/fee', data);
      return response.data;
    },
  },

  namespaced: true,
});
