//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import Header from '~/components/general/Header.vue';
import Navigation from '~/components/general/Navigation.vue';

export default {
  components: {
    Header,
    Navigation,
  },

  data() {
    return {
      ready: false,
    };
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.getCurrentAdmin(),
      this.getCurrencies(),
      this.getMarkets(),
      this.getUserTagsList(),
      this.loadAdmins(), // TODO: обработать ситуацию, когда метод недоступен для аккаунта
      this.getStatus(),
    ]);
    this.setGeneralLoader(false);

    const { path, matched } = this.$route;
    const isRouteAccessible = this.getRoutesAccessibility(matched);

    if (!isRouteAccessible) {
      this.redirectToSafeSection();
    } else if (path === '/') {
      this.$router.push({ name: 'users' });
    }

    this.ready = true;
  },

  beforeRouteUpdate(to, from, next) {
    const isRouteAccessible = this.getRoutesAccessibility(to.matched);
    if (isRouteAccessible) {
      next();
    } else {
      this.redirectToSafeSection();
    }
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader', 'getStatus']),
    ...mapActions('login', ['getCurrentAdmin', 'loadAdmins']),
    ...mapActions('currencies', ['getCurrencies', 'getMarkets']),
    ...mapActions('users', ['getUserTagsList']),

    getRoutesAccessibility(routes) {
      const { getRouteAccessibility } = this.$utils;
      return routes.every((e) => getRouteAccessibility(e.name));
    },

    redirectToSafeSection() {
      const { name } = this.$route;
      if (name !== 'account') {
        this.$router.push({ name: 'account' });
      }
    },
  },
};
