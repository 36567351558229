import http from '~/http/index';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Transaction/GetCryptoTokens', {
        ...getters.requestData,
        ...data,
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async createCryptoToken(__store, data) {
      const response = await http.post('v2/Transaction/CreateCryptoToken', data);
      return response.data;
    },

    async editCryptoToken(__store, data) {
      const response = await http.put('v2/Transaction/EditCryptoToken', data);
      return response.data;
    },

    async deleteCryptoToken(__store, id) {
      const response = await http.put('v2/Transaction/DeleteCryptoToken', { id });
      return response.data;
    },
  },

  namespaced: true,
});
