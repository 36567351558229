const ACCESS_TYPE = {
  ALLOW: 'Allow',
  DENY: 'Deny',
};

const ACCESS_TYPE_LIST = [
  {
    id: ACCESS_TYPE.ALLOW,
    caption: 'Allow',
  },
  {
    id: ACCESS_TYPE.DENY,
    caption: 'Deny',
  },
];

export default {
  ACCESS_TYPE,
  ACCESS_TYPE_LIST,
};
