import http from '~/http/index';

export default ({
  state: {
    options: {},
  },

  mutations: {
    SET_OPTIONS(state, data) {
      state.options = data;
    },
  },

  actions: {
    async loadData({ commit }) {
      const { data } = await http.get('v2/Options/GetReferralOptions');
      commit('SET_OPTIONS', data);
    },

    async updateReferralOptions(__store, data) {
      const response = await http.post('v2/Options/UpdateReferralOptions', data);
      return response.data;
    },
  },

  namespaced: true,
});
