var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-component",class:_vm.className},[_c('v-card',{attrs:{"outlined":""}},[_c('v-simple-table',{attrs:{"fixed-header":_vm.fixedHeader,"height":_vm.height},scopedSlots:_vm._u([{key:"default",fn:function(){return [(!_vm.hideHeader)?_c('thead',[_c('tr',{staticClass:"table-component__header"},_vm._l((_vm.config),function(column,i){return _c('th',{key:'header_' + i,staticClass:"text--primary body-2 font-weight-medium",attrs:{"width":column.width + '%'}},[_c('div',{staticClass:"d-flex align-center",class:column.header.className},[(_vm.showExtra && i === 0)?_c('v-icon',{staticClass:"table-component__chevron mr-2",class:{
                    'table-component__chevron--down': _vm.isSomeExtra,
                    'mr-1': _vm.showSelect,
                  },attrs:{"dense":""},on:{"click":_vm.toggleAllExtra}},[_vm._v(" mdi-chevron-right ")]):_vm._e(),(i === 0 && _vm.showSelect)?_c('i',{staticClass:"mr-9 table-component__checkbox-container"},[_c('v-simple-checkbox',{staticClass:"d-inline table-component__checkbox",attrs:{"color":"primary","value":_vm.isSelectedAllTable || _vm.isSelectedAll},on:{"input":function($event){return _vm.selectAllTable($event)}}})],1):_vm._e(),(i === 0 && _vm.showActions)?_c('i',{staticClass:"mr-5 table-component__menu-container"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"table-component__menu",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_vm._t("actions")],2)],1):_vm._e(),(column.header.type === 'text')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(column.header.caption &&
                          (_vm.$t(("tableHeader." + (column.header.caption))) !== ("tableHeader." + (column.header.caption)))
                        )?_c('span',_vm._g(_vm._b({staticClass:"table-component__text-header text-truncate"},'span',attrs,false),on),[_vm._v(" "+_vm._s(column.header.caption ? _vm.$t(("tableHeader." + (column.header.caption))) : '')+" ")]):_c('span',_vm._g(_vm._b({staticClass:"table-component__text-header text-truncate"},'span',attrs,false),on),[_vm._v(" "+_vm._s(column.header.caption)+" ")])]}}],null,true)},[_c('span',[(column.header.caption &&
                          (_vm.$t(("tableHeader." + (column.header.caption))) !== ("tableHeader." + (column.header.caption)))
                        )?[_vm._v(" "+_vm._s(column.header.caption ? _vm.$t(("tableHeader." + (column.header.caption))) : '')+" ")]:[_vm._v(" "+_vm._s(column.header.caption)+" ")]],2)])]:_vm._e(),(column.header.type === 'slot')?[_vm._t(column.header.name)]:_vm._e(),(column.header.sort)?_c('v-btn',{class:{'primary--text': column.header.sort === _vm.sort.sort},attrs:{"icon":""},on:{"click":function($event){return _vm.setSort(
                    column.header.sort,
                    column.header.sort === _vm.sort.sort ? !_vm.sort.sortDesc : false
                  )}}},[(_vm.sort.sort)?_c('v-icon',{class:{
                      'table-component__sort-icon--desc': (
                        column.header.sort === _vm.sort.sort
                        && _vm.sort.sortDesc
                      )
                    }},[_vm._v(" mdi-arrow-up ")]):_vm._e()],1):_vm._e()],2)])}),0)]):_vm._e(),_c('tbody',[(_vm.isLoading)?_vm._l((_vm.perPage),function(row,i){return _c('tr',{key:'row_' + i},_vm._l((_vm.config),function(column,j){return _c('td',{key:("cell_" + i + "-" + j),attrs:{"width":column.width + '%'}},[_c('v-skeleton-loader',{attrs:{"type":"table-cell","width":"auto","max-width":88,"boilerplate":""}})],1)}),0)}):[(_vm.data.length)?[_vm._l((_vm.data),function(row,i){return [_c('tr',{key:'row_' + i,staticClass:"table-component__body-row",class:{
                    'table-component__body-row--no-extra': !_vm.showExtra && _vm.highlighted === null,
                    'grey lighten-3': _vm.isHighlighted(row) && !_vm.$vuetify.theme.dark,
                    'grey darken-2': _vm.isHighlighted(row) && _vm.$vuetify.theme.dark,
                  },style:(_vm.getRowStyle(i)),on:{"click":function($event){return _vm.toggleExtra(row)}}},_vm._l((_vm.config),function(column,j){
                  var _obj;
return _c('td',{key:("cell_" + i + "-" + j),staticClass:"table-component__body-cell",class:( _obj = {}, _obj[column.cell.class] = column.cell.class, _obj['text-truncate'] =  column.cell.type === 'text', _obj['table-component__body-cell--last'] =  i === _vm.data.length - 1, _obj['table-component__body-cell--extra'] =  _vm.isExtra(row), _obj )},[(j === 0)?[(_vm.showExtra)?_c('v-icon',{staticClass:"table-component__chevron mr-2",class:{
                          'table-component__chevron--down': _vm.isExtra(row),
                          'mr-1': _vm.showSelect,
                        },attrs:{"dense":""}},[_vm._v(" mdi-chevron-right ")]):_vm._e(),(_vm.showSelect)?_c('i',{staticClass:"mr-9 table-component__checkbox-container"},[_c('v-simple-checkbox',{staticClass:"d-inline table-component__checkbox",attrs:{"color":"primary","value":_vm.isSelected(row) || _vm.isSelectedAll},on:{"input":function($event){return _vm.select(row, $event)}}})],1):_vm._e()]:_vm._e(),(column.cell.type === 'text')?[(column.cell.tooltip)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({on:{"click":function($event){_vm.copyToClipboard(column.cell.value(row))}}},'span',attrs,false),on),[_vm._v(_vm._s(column.cell.value(row)))])]}}],null,true)},[_vm._v(" "+_vm._s(column.cell.value(row))+" ")])]:[_vm._v(" "+_vm._s(column.cell.value(row))+" ")]]:_vm._e(),(column.cell.type === 'slot')?[_vm._t(column.cell.name,null,{"row":row})]:_vm._e()],2)}),0),_c('tr',{key:'row-extra_' + i,staticClass:"table-component__extra"},[_c('td',{class:{'table-component__extra-col--collapsed': !_vm.isExtra(row)},attrs:{"colspan":_vm.config.length}},[_c('v-expand-transition',[(_vm.isExtra(row))?_vm._t("extra",null,{"row":row}):_vm._e()],2)],1)])]})]:[_c('tr',{staticClass:"table-component__empty"},[_c('td',{staticClass:"text--secondary overline",attrs:{"align":"center","colspan":_vm.config.length}},[_vm._v(" "+_vm._s(_vm.$t('common.Nothing to show'))+" ")])])]]],2)]},proxy:true}],null,true)}),(_vm.isSelectedAll)?_c('div',{staticClass:"table-component__is-selected-all",style:(_vm.isSelectedAllStyle)},[_c('v-btn',{attrs:{"color":"primary","depressed":"","height":37},on:{"click":_vm.removeSelection}},[_c('span',{staticClass:"body-2 pr-4 table-component__is-selected-all-text"},[_vm._v(_vm._s(_vm.$t('common.Selected all')))]),_vm._v(" "+_vm._s(_vm.$t('common.Remove selection'))+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }