//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      isShow: false,
    };
  },

  computed: {
    ...mapState('common', ['errorNotification']),
  },

  watch: {
    errorNotification(value) {
      if (value) {
        this.isShow = true;
      }
    },
  },

  methods: {
    ...mapActions('common', ['setErrorNotification']),

    show(value) {
      if (!value) {
        this.setErrorNotification('');
      }
    },
  },
};
