export default [
  {
    path: '',
    name: 'finance-balances',
    component: () => import(/* webpackChunkName: "finance-balances" */ '~/views/FinanceBalances/FinanceBalances.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderFinance' },
    },
  },

  {
    path: 'wallets',
    name: 'finance-wallets',
    component: () => import(/* webpackChunkName: "finance-wallets" */ '~/views/FinanceWallets/FinanceWallets.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderFinance' },
      rootRouteName: 'finance-balances',
    },
  },
];
