import checkConstAvailability from '~/utils/check-const-availability';

const ORDER_TYPE = {
  LIMIT: 0,
  MARKET: 1,
  STOP: 2,
  STOP_LIMIT: 3,
  TRAILING_STOP: 4,
  FILL_OR_KILL: 5,
};

const ORDER_STATUS = {
  NEW: 0,
  PARTIALLY_FILLED: 1,
  FILLED: 2,
  CANCELLED: 3,
  INACTIVE: 4,
  AWAITING: 5,
  DELETED: 6,
  REJECTED: 7,
};

const ORDER_SIDE = {
  BID: 0,
  ASK: 1,
};

const ORDER_TYPE_LIST = [
  {
    id: ORDER_TYPE.LIMIT,
    caption: 'Limit',
  },
  {
    id: ORDER_TYPE.MARKET,
    caption: 'Market',
  },
  {
    id: ORDER_TYPE.STOP,
    caption: 'Stop',
  },
  {
    id: ORDER_TYPE.STOP_LIMIT,
    caption: 'Stop limit',
  },
  {
    id: ORDER_TYPE.TRAILING_STOP,
    caption: 'Trailing stop',
  },
  {
    id: ORDER_TYPE.FILL_OR_KILL,
    caption: 'Fill or kill',
  },
].filter((e) => checkConstAvailability(e, { ORDER_TYPE }));

const ORDER_STATUS_LIST = [
  {
    id: ORDER_STATUS.NEW,
    caption: 'New',
  },
  {
    id: ORDER_STATUS.PARTIALLY_FILLED,
    caption: 'Partially filled',
  },
  {
    id: ORDER_STATUS.FILLED,
    caption: 'Filled',
  },
  {
    id: ORDER_STATUS.CANCELLED,
    caption: 'Cancelled',
  },
  {
    id: ORDER_STATUS.INACTIVE,
    caption: 'Inactive',
  },
  // {
  //   id: ORDER_STATUS.AWAITING,
  //   caption: 'Awaiting',
  // },
  // {
  //   id: ORDER_STATUS.DELETED,
  //   caption: 'Deleted',
  // },
  {
    id: ORDER_STATUS.REJECTED,
    caption: 'Rejected',
  },
].filter((e) => checkConstAvailability(e, { ORDER_STATUS }));

export default {
  ORDER_TYPE,
  ORDER_TYPE_LIST,
  ORDER_STATUS,
  ORDER_STATUS_LIST,
  ORDER_SIDE,
};
