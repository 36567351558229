export default [
  {
    path: '',
    name: 'settings-technical',
    component: () => import(/* webpackChunkName: "settings-technical" */ '~/views/Settings/SettingsTechnicalWork/SettingsTechnicalWork.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderSettings' },
    },
  },

  {
    path: 'limits',
    name: 'settings-limits',
    component: () => import(/* webpackChunkName: "settings-limits" */ '~/views/Settings/SettingsLimits/SettingsLimits.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderSettings' },
      rootRouteName: 'settings-technical',
    },
  },

  {
    path: 'notifications',
    name: 'settings-notifications',
    component: () => import(/* webpackChunkName: "settings-notifications" */ '~/views/Settings/SettingsNotifications/SettingsNotifications.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderSettings' },
      rootRouteName: 'settings-technical',
    },
  },

  {
    path: 'email',
    name: 'settings-email',
    component: () => import(/* webpackChunkName: "settings-email" */ '~/views/Settings/SettingsEmail/SettingsEmail.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderSettings' },
      rootRouteName: 'settings-technical',
    },
  },
];
