import http from '~/http/index';

export default ({
  state: {
    data: [],
    cryptoCurrencies: [],
    cryptoTokens: [],
  },

  getters: {
    cryptoCurrencies(state) {
      return state.cryptoCurrencies;
    },
    cryptoTokens(state) {
      return state.cryptoTokens;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_CRYPTO_CURRENCIES(state, data) {
      state.cryptoCurrencies = data.map((item) => {
        return {
          ...item, name: `${item.name}(${item.title})`,
        };
      });
    },
    SET_CRYPTO_TOKENS(state, data) {
      state.cryptoTokens = data.map((item) => {
        return {
          ...item, name: `${item.name}(${item.title})`,
        };
      });
    },
  },

  actions: {
    async loadData({ commit }, data) {
      const response = await http.post('v2/Market/GetCurrencies', {
        all: true,
        sortDesc: false,
        transactionMethodsCount: true,
        ...data,
      });
      const { items } = response.data;
      commit('SET_DATA', items);
    },

    async loadCryptoCurrencies({ commit }, data = {}) {
      const response = await http.post('v2/Transaction/GetCryptoCurrencies', data);
      const { items } = response.data;
      commit('SET_CRYPTO_CURRENCIES', items);
    },

    async loadCryptoTokens({ commit }, data = {}) {
      const response = await http.post('v2/Transaction/GetCryptoTokens', data);
      const { items } = response.data;
      commit('SET_CRYPTO_TOKENS', items);
    },
  },

  namespaced: true,
});
