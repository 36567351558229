const { localStorage } = window;

const getToken = () => {
  const token = localStorage.getItem('accessToken');
  return token;
};

const setToken = (data) => {
  localStorage.setItem('accessToken', data);
};

const clearToken = () => {
  localStorage.removeItem('accessToken');
};

const setStorageIsDarkTheme = (data) => {
  localStorage.setItem('isDarkTheme', data);
};

const getStorageIsDarkTheme = () => {
  return localStorage.getItem('isDarkTheme') === 'true';
};

export {
  getToken,
  setToken,
  clearToken,
  setStorageIsDarkTheme,
  getStorageIsDarkTheme,
};
