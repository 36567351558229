import checkConstAvailability from '~/utils/check-const-availability';

const DEVICE_TYPE = {
  DEVICE_TYPE_UNKNOWN: 0,
  DEVICE_TYPE_SMARTPHONE: 1,
  DEVICE_TYPE_IPPHONE: 2,
};

const DEVICE_TYPE_IOTA = {
  0: 'DEVICE_TYPE_UNKNOWN',
  1: 'DEVICE_TYPE_SMARTPHONE',
  2: 'DEVICE_TYPE_IPPHONE',
};

const DEVICE_TYPE_TITLES = {
  0: 'Unknown',
  DEVICE_TYPE_UNKNOWN: 'Unknown',
  1: 'Smartphone',
  DEVICE_TYPE_SMARTPHONE: 'Smartphone',
  2: 'IPphone',
  DEVICE_TYPE_IPPHONE: 'IPphone',
};

const DEVICE_TYPE_LIST = [
  {
    id: DEVICE_TYPE_IOTA[DEVICE_TYPE.DEVICE_TYPE_UNKNOWN],
    caption: 'Unknown',
  },
  {
    id: DEVICE_TYPE_IOTA[DEVICE_TYPE.DEVICE_TYPE_SMARTPHONE],
    caption: 'Smartphone',
  },
  {
    id: DEVICE_TYPE_IOTA[DEVICE_TYPE.DEVICE_TYPE_IPPHONE],
    caption: 'IPphone',
  },
].filter((e) => checkConstAvailability(e, { DEVICE_TYPE }));

const REQUISITE_TYPE = {
  REQUISITE_STATUS_ENABLED: 0,
  REQUISITE_STATUS_STOPPED: 1,
  REQUISITE_STATUS_BLOCKED: 2,
  REQUISITE_STATUS_DELETED: 3,
};

const REQUISITE_TYPE_IOTA = {
  0: 'Enabled',
  1: 'Stopped',
  2: 'Blocked',
  3: 'Deleted',
};

const REQUISITE_TYPE_LIST = [
  {
    id: 'REQUISITE_STATUS_ENABLED',
    caption: REQUISITE_TYPE_IOTA[REQUISITE_TYPE.REQUISITE_STATUS_ENABLED],
  },
  {
    id: 'REQUISITE_STATUS_STOPPED',
    caption: REQUISITE_TYPE_IOTA[REQUISITE_TYPE.REQUISITE_STATUS_STOPPED],
  },
  {
    id: 'REQUISITE_STATUS_BLOCKED',
    caption: REQUISITE_TYPE_IOTA[REQUISITE_TYPE.REQUISITE_STATUS_BLOCKED],
  },
  {
    id: 'REQUISITE_STATUS_DELETED',
    caption: REQUISITE_TYPE_IOTA[REQUISITE_TYPE.REQUISITE_STATUS_DELETED],
  },
].filter((e) => checkConstAvailability(e, { REQUISITE_TYPE }));

const OFFER_STATUS = {
  OFFER_STATUS_ENABLED: 0,
  OFFER_STATUS_STOPPED: 1,
  OFFER_STATUS_BLOCKED: 2,
  OFFER_STATUS_DELETED: 3,
};

const OFFER_STATUS_IOTA = {
  0: 'Enabled',
  1: 'Stopped',
  2: 'Blocked',
  3: 'Deleted',
};

const OFFER_STATUS_LIST = [
  {
    id: OFFER_STATUS.OFFER_STATUS_ENABLED,
    caption: OFFER_STATUS_IOTA[OFFER_STATUS.OFFER_STATUS_ENABLED],
  },
  {
    id: OFFER_STATUS.OFFER_STATUS_STOPPED,
    caption: OFFER_STATUS_IOTA[OFFER_STATUS.OFFER_STATUS_STOPPED],
  },
  {
    id: OFFER_STATUS.OFFER_STATUS_BLOCKED,
    caption: OFFER_STATUS_IOTA[OFFER_STATUS.OFFER_STATUS_BLOCKED],
  },
  {
    id: OFFER_STATUS.OFFER_STATUS_DELETED,
    caption: OFFER_STATUS_IOTA[OFFER_STATUS.OFFER_STATUS_DELETED],
  },
].filter((e) => checkConstAvailability(e, { OFFER_STATUS }));

const FLOW_TYPE = {
  BUY: 'FLOW_TYPE_BUY',
  SELL: 'FLOW_TYPE_SELL',
};

const FLOW_TYPE_IOTA = {
  FLOW_TYPE_BUY: 'BUY',
  FLOW_TYPE_SELL: 'SELL',
};

const TRANSACTION_TYPE_CAPTION = {
  DEPOSIT: 'TxType_Deposit',
  WITHDRAWAL: 'TxType_Withdrawal',
  ORDER_BUY: 'TxType_OrderBuy',
  CONVERT: 'TxType_Convert',
  ORDER_SELL: 'TxType_OrderSell',
  ORDER_RETURN: 'TxType_OrderReturn',
};

const TRANSACTION_TYPE_CAPTION_IOTA = {
  [TRANSACTION_TYPE_CAPTION.DEPOSIT]: 'DEPOSIT',
  [TRANSACTION_TYPE_CAPTION.WITHDRAWAL]: 'WITHDRAWAL',
  [TRANSACTION_TYPE_CAPTION.ORDER_BUY]: 'ORDER_BUY',
  [TRANSACTION_TYPE_CAPTION.CONVERT]: 'CONVERT',
  [TRANSACTION_TYPE_CAPTION.ORDER_SELL]: 'ORDER_SELL',
  [TRANSACTION_TYPE_CAPTION.ORDER_RETURN]: 'ORDER_RETURN',
};

const TRANSACTION_TYPE = {
  0: TRANSACTION_TYPE_CAPTION.DEPOSIT,
  1: TRANSACTION_TYPE_CAPTION.WITHDRAWAL,
  2: TRANSACTION_TYPE_CAPTION.ORDER_BUY,
  3: TRANSACTION_TYPE_CAPTION.CONVERT,
  4: TRANSACTION_TYPE_CAPTION.ORDER_SELL,
  5: TRANSACTION_TYPE_CAPTION.ORDER_RETURN,
  [TRANSACTION_TYPE_CAPTION.DEPOSIT]: 0,
  [TRANSACTION_TYPE_CAPTION.WITHDRAWAL]: 1,
  [TRANSACTION_TYPE_CAPTION.ORDER_BUY]: 2,
  [TRANSACTION_TYPE_CAPTION.CONVERT]: 3,
  [TRANSACTION_TYPE_CAPTION.ORDER_SELL]: 4,
  [TRANSACTION_TYPE_CAPTION.ORDER_RETURN]: 5,
};

const TRANSACTION_TYPE_LIST = [
  { id: TRANSACTION_TYPE[TRANSACTION_TYPE_CAPTION.DEPOSIT], caption: TRANSACTION_TYPE_CAPTION.DEPOSIT },
  { id: TRANSACTION_TYPE[TRANSACTION_TYPE_CAPTION.WITHDRAWAL], caption: TRANSACTION_TYPE_CAPTION.WITHDRAWAL },
  { id: TRANSACTION_TYPE[TRANSACTION_TYPE_CAPTION.CONVERT], caption: TRANSACTION_TYPE_CAPTION.CONVERT },
  { id: TRANSACTION_TYPE[TRANSACTION_TYPE_CAPTION.ORDER_BUY], caption: TRANSACTION_TYPE_CAPTION.ORDER_BUY },
  { id: TRANSACTION_TYPE[TRANSACTION_TYPE_CAPTION.ORDER_SELL], caption: TRANSACTION_TYPE_CAPTION.ORDER_SELL },
  { id: TRANSACTION_TYPE[TRANSACTION_TYPE_CAPTION.ORDER_RETURN], caption: TRANSACTION_TYPE_CAPTION.ORDER_RETURN },
];

const TRANSACTION_METHOD_TYPE = {
  CRYPTO: 'TxMethod_Crypto',
};

const TRANSACTION_METHOD_TYPE_IOTA = {
  [TRANSACTION_METHOD_TYPE.CRYPTO]: 'Crypto',
};

const TRANSACTION_METHOD_TYPE_LIST = [
  { id: TRANSACTION_METHOD_TYPE.CRYPTO, caption: TRANSACTION_METHOD_TYPE_IOTA[TRANSACTION_METHOD_TYPE.CRYPTO] },
];

const PAYMENT_METHOD_STATUS = {
  ENABLED: 'PAYMENT_METHOD_STATUS_ENABLED',
  DISABLED: 'PAYMENT_METHOD_STATUS_DISABLED',
  DELETED: 'PAYMENT_METHOD_STATUS_DELETED',
};

const PAYMENT_METHOD_STATUS_LIST = [
  {
    id: PAYMENT_METHOD_STATUS.ENABLED,
    caption: 'Enabled',
  },
  {
    id: PAYMENT_METHOD_STATUS.DISABLED,
    caption: 'Disabled',
  },
  {
    id: PAYMENT_METHOD_STATUS.DELETED,
    caption: 'Deleted',
  },
];

const CURRENCY_TYPE = {
  CRYPTO: 'CURRENCY_TYPE_CRYPTO',
  FIAT: 'CURRENCY_TYPE_FIAT',
};

const CURRENCY_TYPE_LIST = [
  {
    id: CURRENCY_TYPE.CRYPTO,
    caption: 'Crypto',
  },
  {
    id: CURRENCY_TYPE.FIAT,
    caption: 'Fiat',
  },
];

const TRANSACTION_STATUS_CAPTION = {
  NEW: 'TxType_New',
  PROCESSING: 'TxType_Processing',
  COMPLETED: 'TxType_Completed',
  AWAITING: 'TxType_Awaiting',
  ERROR: 'TxType_Error',
  FROZEN: 'TxType_Frozen',
  QUEUE: 'TxType_Queue',
  CANCELLED: 'TxType_Cancelled',
};

const TRANSACTION_STATUS_CAPTION_IOTA = {
  [TRANSACTION_STATUS_CAPTION.NEW]: 'NEW',
  [TRANSACTION_STATUS_CAPTION.PROCESSING]: 'PROCESSING',
  [TRANSACTION_STATUS_CAPTION.COMPLETED]: 'COMPLETED',
  [TRANSACTION_STATUS_CAPTION.AWAITING]: 'AWAITING',
  [TRANSACTION_STATUS_CAPTION.ERROR]: 'ERROR',
  [TRANSACTION_STATUS_CAPTION.FROZEN]: 'FROZEN',
  [TRANSACTION_STATUS_CAPTION.QUEUE]: 'QUEUE',
  [TRANSACTION_STATUS_CAPTION.CANCELLED]: 'CANCELLED',
};

const TRANSACTION_STATUS = {
  0: TRANSACTION_STATUS_CAPTION.NEW,
  1: TRANSACTION_STATUS_CAPTION.PROCESSING,
  2: TRANSACTION_STATUS_CAPTION.COMPLETED,
  3: TRANSACTION_STATUS_CAPTION.AWAITING,
  4: TRANSACTION_STATUS_CAPTION.ERROR,
  5: TRANSACTION_STATUS_CAPTION.FROZEN,
  6: TRANSACTION_STATUS_CAPTION.QUEUE,
  7: TRANSACTION_STATUS_CAPTION.CANCELLED,
  [TRANSACTION_STATUS_CAPTION.NEW]: 0,
  [TRANSACTION_STATUS_CAPTION.PROCESSING]: 1,
  [TRANSACTION_STATUS_CAPTION.COMPLETED]: 2,
  [TRANSACTION_STATUS_CAPTION.AWAITING]: 3,
  [TRANSACTION_STATUS_CAPTION.ERROR]: 4,
  [TRANSACTION_STATUS_CAPTION.FROZEN]: 5,
  [TRANSACTION_STATUS_CAPTION.QUEUE]: 6,
  [TRANSACTION_STATUS_CAPTION.CANCELLED]: 7,
};

const TRANSACTION_STATUS_LIST = [
  { id: TRANSACTION_STATUS[TRANSACTION_STATUS_CAPTION.NEW], caption: TRANSACTION_STATUS_CAPTION.NEW },
  { id: TRANSACTION_STATUS[TRANSACTION_STATUS_CAPTION.PROCESSING], caption: TRANSACTION_STATUS_CAPTION.PROCESSING },
  { id: TRANSACTION_STATUS[TRANSACTION_STATUS_CAPTION.COMPLETED], caption: TRANSACTION_STATUS_CAPTION.COMPLETED },
  { id: TRANSACTION_STATUS[TRANSACTION_STATUS_CAPTION.AWAITING], caption: TRANSACTION_STATUS_CAPTION.AWAITING },
  { id: TRANSACTION_STATUS[TRANSACTION_STATUS_CAPTION.ERROR], caption: TRANSACTION_STATUS_CAPTION.ERROR },
  { id: TRANSACTION_STATUS[TRANSACTION_STATUS_CAPTION.FROZEN], caption: TRANSACTION_STATUS_CAPTION.FROZEN },
  { id: TRANSACTION_STATUS[TRANSACTION_STATUS_CAPTION.QUEUE], caption: TRANSACTION_STATUS_CAPTION.QUEUE },
  { id: TRANSACTION_STATUS[TRANSACTION_STATUS_CAPTION.CANCELLED], caption: TRANSACTION_STATUS_CAPTION.CANCELLED },
];

const ACTIONS = {
  CONFIRM: {
    caption: 'Confirm',
    action: 'confirm',
  },
  COMPLETE: {
    caption: 'Complete',
    action: 'complete',
  },
  CANCEL: {
    caption: 'Cancel',
    action: 'cancel',
  },
};

const STATUS_ACTIONS_CONFIG = {
  [TRANSACTION_STATUS_CAPTION.ERROR]: [
    ACTIONS.COMPLETE,
    ACTIONS.CONFIRM,
    ACTIONS.CANCEL,
  ],
  [TRANSACTION_STATUS_CAPTION.AWAITING]: [
    ACTIONS.COMPLETE,
    ACTIONS.CONFIRM,
    ACTIONS.CANCEL,
  ],
  [TRANSACTION_STATUS_CAPTION.FROZEN]: [
    ACTIONS.CANCEL,
  ],
};

export default {
  DEVICE_TYPE,
  DEVICE_TYPE_IOTA,
  DEVICE_TYPE_LIST,
  DEVICE_TYPE_TITLES,
  REQUISITE_TYPE,
  REQUISITE_TYPE_IOTA,
  REQUISITE_TYPE_LIST,
  OFFER_STATUS,
  OFFER_STATUS_IOTA,
  OFFER_STATUS_LIST,
  FLOW_TYPE,
  FLOW_TYPE_IOTA,
  TRANSACTION_TYPE_CAPTION,
  TRANSACTION_TYPE_CAPTION_IOTA,
  TRANSACTION_TYPE,
  PAYMENT_METHOD_STATUS,
  PAYMENT_METHOD_STATUS_LIST,
  CURRENCY_TYPE,
  CURRENCY_TYPE_LIST,
  TRANSACTION_STATUS_CAPTION,
  TRANSACTION_STATUS_CAPTION_IOTA,
  TRANSACTION_STATUS,
  STATUS_ACTIONS_CONFIG,
  ACTIONS,
  TRANSACTION_TYPE_LIST,
  TRANSACTION_STATUS_LIST,
  TRANSACTION_METHOD_TYPE,
  TRANSACTION_METHOD_TYPE_IOTA,
  TRANSACTION_METHOD_TYPE_LIST,
};
