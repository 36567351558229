import http from '~/http/index';

export default ({
  state: {
    publicChats: [],
    filters: {},
    sort: {
      sort: 'id',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_PUBLIC_CHATS(state, data) {
      state.publicChats = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async getPublicChats({ getters, commit, rootGetters }, params) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Communication/GetPublicChatMessages', {
        ...getters.requestData,
        ...params,
      });

      const { items, total } = response.data;
      commit('SET_PUBLIC_CHATS', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async editChatMessage(__store, data) {
      await http.put('v2/Communication/EditPublicChatMessage', data);
    },

    async deleteMessages(__store, messageIdList) {
      await http.put('v2/Communication/DeletePublicChatMessages', { messageIdList });
    },
  },

  namespaced: true,
});
