/* eslint-disable no-unused-vars, no-bitwise */

import constant from '~/const';
import store from '../store/index';

const {
  ADMIN,
  SUPER_ADMIN,
  SUPPORT,
  OWNER,
  OPERATOR,
  MARKETING,
  ARBITRATOR,
  SECURITY,
  FINANCIAL_DEPARTMENT,
  AGENT,
  VERIFIER,
  FINANCIER,
} = constant.admin.ADMIN_STATUS;

const ROUTES_DATA = [
  {
    name: 'dashboard',
    isAllowEveryone: true,
    exceptions: [],
  },
  {
    name: 'users',
    isAllowEveryone: true,
    exceptions: [],
  },
];

const SECTIONS_DATA = [
  {
    name: 'dashboard',
    isAllowEveryone: true,
    exceptions: [],
  },
  {
    name: 'users',
    isAllowEveryone: true,
    exceptions: [],
  },
];

const getAccessibility = (name, data) => {
  const item = data.find((e) => e.name === name);
  const { adminStatus } = store.state.login.currentAdmin;
  if (!item) return true;

  const { isAllowEveryone, exceptions } = item;
  if (!exceptions) return isAllowEveryone;

  const isInExceptions = exceptions.includes(adminStatus);
  return isAllowEveryone ^ isInExceptions; // XOR
};

export const getRouteAccessibility = (name) => getAccessibility(name, ROUTES_DATA);
export const getSectionAccessibility = (name) => getAccessibility(name, SECTIONS_DATA);
