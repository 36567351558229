export default [
  { title: 'ID', routeName: 'user-id' },

  { title: 'Security', routeName: 'user-security' },

  { title: 'Balance', routeName: 'user-balance' },

  { title: 'Trading', routeName: 'user-trading' },


  { title: 'API', routeName: 'user-api' },

  { title: 'Chats', routeName: 'user-chats' },

  { title: 'Notifications', routeName: 'user-notifications' },

  { title: 'Referrals', routeName: 'user-referrals' },

  { title: 'Staking', routeName: 'user-staking' },

];
