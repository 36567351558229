import http from '~/http/index';

export default ({
  state: {},

  getters: {},

  mutations: {},

  actions: {
    async changePassword(__store, data) {
      const response = await http.post('v2/Login/ChangePassword', data);
      return response.data;
    },
  },

  namespaced: true,
});
