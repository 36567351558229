//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    value: { type: [String, Number], default: '' },
    label: { type: String, default: '' },
    type: { type: String, default: 'text' },
    width: { type: String, default: '' },
  },

  computed: {
    style() {
      return {
        width: this.width,
      };
    },
  },

  methods: {
    keydownHandler(event) {
      this.$emit('input', event.target.value);
      this.$emit('submit', event.target.value);
    },

    changeHandler(event) {
      this.$emit('input', event.target.value.trim());
    },
  },
};
