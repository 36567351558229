//
//
//
//
//
//
//
//
//

import SuccessNotification from '~/components/notifications/Success.vue';
import ErrorNotification from '~/components/notifications/Error.vue';
import GeneralConfirm from '~/components/GeneralConfirm.vue';

export default {
  components: {
    SuccessNotification,
    ErrorNotification,
    GeneralConfirm,
  },
};
