import http from '~/http/index';

export default ({
  state: {
    data: [],
    paraminingData: {},
    filters: {},
    sort: {
      sort: 'id',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters, sort } = state;
      return {
        ...filters,
        ...sort,
        all: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_PARAMINING_DATA(state, data) {
      state.paraminingData = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.put('v2/Staking/GetStakingDataList', {
        ...getters.requestData,
        ...data,
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async editCurrency(__store, data) {
      const response = await http.put('v2/Market/EditCurrency', data);
      return response.data;
    },

    async getParaminingData({ commit }, data) {
      const response = await http.put('v2/Staking/GetStakingData', data);
      commit('SET_PARAMINING_DATA', response.data);
    },

    async addStakingSettings(__store, data) {
      const response = await http.put('v2/Staking/AddStakingSettings', data);
      return response.data;
    },

    async removeStakingSettings(__store, data) {
      const response = await http.put('v2/Staking/RemoveStakingSettings', data);
      return response.data;
    },

    async updateParaminingPerc(__store, data) {
      const response = await http.put('v2/Staking/UpdateUserStaking', data);
      return response.data;
    },
  },

  namespaced: true,
});
