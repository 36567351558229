import checkConstAvailability from '~/utils/check-const-availability';

const REPORT_TYPE = {
  TOTAL_BALANCES: 'TotalBalances',
  USERS: 'Users',
  PUBLIC_CHAT_MESSAGES: 'PublicChatMessages',
  PRIVATE_CHATS: 'PrivateChats',
  PRIVATE_CHAT_MESSAGES: 'PrivateChatMessages',
  BALANCE_INFO: 'BalanceInfo',
  TRANSACTION_STATS: 'TransactionStats',
  TRANSACTIONS: 'Transactions',
  OPERATIONS: 'Operations',
  TRADES: 'Trades',
  ORDERS: 'Orders',
  USER_ACTIONS: 'UserActions',
  REFERRAL_PROGRAM_INCOMES: 'ReferralProgramIncomes',
  PARAMINING_LOGS: 'StakingLogs',
  STAKING_LOGS: 'StakingLogs',
  BALANCES_INFO: 'BalancesInfo',
  P2P_DEALS: 'P2pDeals',
  P2P_OFFERS: 'P2pOffers',
};

const REPORT_STATUS = {
  NEW: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  ERROR: 3,
};

const REPORT_STATUS_LIST = [
  {
    id: REPORT_STATUS.NEW,
    caption: 'New',
  },
  {
    id: REPORT_STATUS.IN_PROGRESS,
    caption: 'In progress',
  },
  {
    id: REPORT_STATUS.COMPLETED,
    caption: 'Completed',
  },
  {
    id: REPORT_STATUS.ERROR,
    caption: 'Error',
  },
].filter((e) => checkConstAvailability(e, { REPORT_STATUS }));

const LIMIT = 1000000;

const IGNORE_USER_TAG = {
  DEV: 'dev',
  TEST: 'test',
  BOT: 'bot',
};

const IGNORE_USER_TAG_LIST = [
  IGNORE_USER_TAG.DEV,
  IGNORE_USER_TAG.TEST,
  IGNORE_USER_TAG.BOT,
].filter((e) => checkConstAvailability(e, { REPORT_STATUS }, (i) => i));

export default {
  REPORT_TYPE,
  LIMIT,
  REPORT_STATUS,
  REPORT_STATUS_LIST,
  IGNORE_USER_TAG_LIST,
};
