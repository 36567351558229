const CURRENCY_TYPE = {
  FIAT: 0,
  CRYPTO: 1,
};

const CURRENCY_TYPE_LIST = [
  {
    id: CURRENCY_TYPE.FIAT,
    caption: 'Fiat',
  },
  {
    id: CURRENCY_TYPE.CRYPTO,
    caption: 'Cryptocurrency',
  },
];

const DEFAULT_PRICE_EQUIVALENT_CURRENCY_TITLE = 'USD';

const PRICE_EQUIVALENT_CURRENCY_LIST = [
  'USD',
  'EUR',
  'RUB',
];

export default {
  CURRENCY_TYPE,
  CURRENCY_TYPE_LIST,
  PRICE_EQUIVALENT_CURRENCY_LIST,
  DEFAULT_PRICE_EQUIVALENT_CURRENCY_TITLE,
};
