//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TextField from '~/components/ui/TextField.vue';

export default {
  components: {
    TextField,
  },

  props: {
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    label: { type: String, default: '' },
    width: { type: String, default: '' },
    from: { type: String, default: '' },
    to: { type: String, default: '' },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  computed: {
    caption() {
      const { from, to } = this;
      if (from && to) {
        return `${from}–${to}`;
      }
      if (from) {
        return this.$t('common.From from', { from });
      }
      if (to) {
        return this.$t('common.To to', { to });
      }
      return ' ';
    },

    isClearable() {
      const { clearable, from, to } = this;
      return clearable && (from || to);
    },

    style() {
      return {
        width: this.width,
      };
    },
  },

  methods: {
    close() {
      this.isOpen = false;
    },

    clearFrom() {
      this.$emit('update:from', '');
    },

    clearTo() {
      this.$emit('update:to', '');
    },

    clearAll() {
      this.clearFrom();
      this.clearTo();
    },

    updateFrom(value) {
      this.$emit('update:from', value);
    },

    updateTo(value) {
      this.$emit('update:to', value);
    },
  },
};
