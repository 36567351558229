const BALANCE_TYPE = {
  TRADING: 0,
  REFERRAL: 1,
  STAKING: 2,
};

const HISTOGRAM_CURRENCY_LIST = [
  {
    title: 'BTC',
    color: '#1644a2',
  },
  {
    title: 'ETH',
    color: '#3f76e6',
  },
  {
    title: 'LTC',
    color: '#b4cdff',
  },
  {
    title: 'Other',
    color: '#e8f0ff',
    default: true,
  },
];

export default {
  BALANCE_TYPE,
  HISTOGRAM_CURRENCY_LIST,
};
