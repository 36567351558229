import http from '~/http/index';
import constant from '~/const';

export default ({
  state: {
    financeStatus: [],
    grossProfitFull: {},
    usersStats: {},
    additionalData: {},
    transactionStats: [],
    p2pStatistic: {},
    data: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      return { ...filters };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_FINANCE_STATUS(state, data) { state.financeStatus = data; },
    SET_GROSS_PROFIT_FULL(state, data) { state.grossProfitFull = data; },
    SET_USERS_STATS(state, data) { state.usersStats = data; },
    SET_TRANSACTION_STATS(state, data) { state.transactionStats = data; },
    SET_P2P_STATISTIC(state, data) { state.p2pStatistic = data; },
    SET_ADDITIONAL_DATA(state, data) { state.additionalData = data; },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('User/GetUsers', {
        ...getters.requestData,
        ...data,
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async loadFinanceStatus({ getters, commit }) {
      const response = await http.post('v2/Finance/GetFinanceStatus', { ...getters.requestData });
      const { items } = response.data;
      commit('SET_FINANCE_STATUS', items);
    },

    async loadGrossProfitFull({ getters, commit }) {
      const response = await http.post('v2/Finance/GetGrossProfitFull', { ...getters.requestData });
      const { data } = response;
      commit('SET_GROSS_PROFIT_FULL', data);
      commit('SET_ADDITIONAL_DATA', {
        cumulativeCurrencyTitle: data.cumulativeCurrencyTitle,
      });
    },

    async loadUsersStats({ getters, commit }) {
      const response = await http.post('v2/User/GetUsersStats', { ...getters.requestData });
      const { data } = response;
      commit('SET_USERS_STATS', data);
    },

    async loadTransactionStats({ getters, commit }) {
      const response = await http.post('v2/Finance/GetTransactionStats', {
        ...getters.requestData,
        ignoreTransactionMethodTypeList: [
          constant.operations.TRANSACTION_METHOD_TYPE.CODE,
          constant.operations.TRANSACTION_METHOD_TYPE.INTERNAL,
        ],
      });
      const { items } = response.data;
      commit('SET_TRANSACTION_STATS', items);
    },

    async loadP2pStatistic({ getters, commit }) {
      const response = await http.get('p2p/Admin/Trade/statistic', {
        params: { ...getters.requestData },
      });
      const { data } = response;
      commit('SET_P2P_STATISTIC', data);
    },
  },

  namespaced: true,
});
