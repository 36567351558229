import http from '~/http/index';

export default ({
  state: {
    modal: {
      show: false,
      requestId: 0,
    },
    userDocuments: [],
    userVerificationRequests: [],
    userFiles: [],
  },

  getters: {
  },

  mutations: {
    SET_USER_DOCUMENTS(state, data) {
      state.userDocuments = data;
    },

    SET_USER_VERIFICATION_REQUESTS(state, data) {
      state.userVerificationRequests = data;
    },

    ADD_USER_FILE(state, { id, data }) {
      const { userFiles } = state;
      userFiles.push({ id, data });
      state.userFiles = userFiles;
    },

    SET_USER_FILES(state, data) {
      state.userFiles = data;
    },

    SET_MODAL(state, data) {
      state.modal = data;
    },
  },

  actions: {
    async loadUserDocuments({ commit, rootState }, data) {
      const { id } = rootState.user.user;
      const response = await http.post('v2/User/GetUserDocuments', {
        userIdList: [id],
        outdated: false,
        limit: 1000,
        offset: 0,
        getTotal: false,
        ...data,
      });
      const { items } = response.data;
      commit('SET_USER_DOCUMENTS', items);
    },

    async loadUserVerificationRequests({ commit, rootState }, data) {
      const { id } = rootState.user.user;
      const response = await http.post('v2/Verification/GetVerificationRequests', {
        userIdList: [id],
        limit: 1000,
        offset: 0,
        getTotal: false,
        ...data,
      });
      const { items } = response.data;
      commit('SET_USER_VERIFICATION_REQUESTS', items);
    },

    async loadUserFile({ state, commit }, UserFileId) {
      // check file loaded
      const { userFiles } = state;
      const isFileLoaded = userFiles.some((e) => e.id === UserFileId);
      if (isFileLoaded) return;

      const { data } = await http.get('User/GetUserFile', {
        params: { UserFileId },
      });
      commit('ADD_USER_FILE', {
        data,
        id: UserFileId,
      });
    },

    cleanUserFiles({ commit }) {
      commit('SET_USER_FILES', []);
    },

    setModal({ commit }, data) {
      commit('SET_MODAL', data);
    },

    async addUserDocument(__store, data) {
      await http.post('v2/User/AddUserDocument', data);
    },

    async updateUserDocument(__store, data) {
      await http.put('v2/User/UpdateUserDocument', data);
    },

    async setUserVerification({ rootState }, verificationStatus) {
      const { id } = rootState.user.user;
      await http.post('User/SetUserVerification', {
        userId: id,
        verificationStatus,
      });
    },

    async adminVerificationResponse(__store, data) {
      const response = await http.put('User/AdminVerificationResponse', data);
      return response.data;
    },

    async setVerificationStatus(__store, data) {
      const response = await http.post('v2/Verification/SetVerificationStatus', data);
      return response.data;
    },

    async manualVerificationResponse(__store, data) {
      const response = await http.post('v2/Verification/ManualVerificationResponse', data);
      return response.data;
    },
  },

  namespaced: true,
});
