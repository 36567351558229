import axios from 'axios';
import moment from 'moment';
import http from '~/http/index';

export default ({
  state: {},

  getters: {},

  mutations: {},

  actions: {
    async createReport(__store, data) {
      const response = await http.put('v2/Report/CreateReport', data);
      return response.data;
    },

    async downloadReport(url, reportType) {
      const date = new Date();
      const dateString = moment(date).format('YYYY-MM-DD, hh:mm');
      const fileName = `Report ${reportType} ${dateString}.csv`;
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });
      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    },
  },

  namespaced: true,
});
