import http from '~/http/index';

export default ({
  state: {
    amounts: [],
    currentCurrencyId: 0,
    chart: {},
    additionalChart: '',
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      return {
        ...filters,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },
    SET_CHART_DATA(state, value) { state.chart = value; },

    SET_DATA(state, data) {
      state.amounts = data.map((item) => {
        return {
          ...item,
          id: item.currencyId,
        };
      });
    },
    SET_ADDITIONAL_CHART(state, data) {
      state.additionalChart = data;
    },
    SET_CURRENT_CURRENCY_ID(state, data) {
      state.currentCurrencyId = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ commit, getters }, data) {
      const response = await http.post('/p2p/Admin/statistic/all', {
        ...getters.requestData,
        ...data,
      });
      const { amounts } = response.data;
      commit('SET_DATA', amounts);
      if (amounts.length > 0) {
        commit('SET_ADDITIONAL_CHART', amounts[0].equivalentCurrencyTitle);
        commit('SET_CURRENT_CURRENCY_ID', amounts[0].currencyId);
      }
    },

    async loadChart({ commit, getters, state }, data) {
      const response = await http.post('/p2p/Admin/statistic/currency', {
        ...getters.requestData,
        ...data,
        currencyId: state.currentCurrencyId,
      });
      const { amounts, equivalentCurrencyTitle } = response.data;
      commit('SET_ADDITIONAL_CHART', { equivalentCurrencyTitle });
      commit('SET_CHART_DATA', amounts);
    },
  },

  namespaced: true,
});
