//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import HeaderUser from '~/components/general/HeaderUser.vue';
import HeaderFinance from '~/components/general/HeaderFinance.vue';
import HeaderStatistics from '~/components/general/HeaderStatistics.vue';
import HeaderP2P from '~/components/general/HeaderP2P.vue';
import HeaderTraders from '~/components/general/HeaderTraders.vue';
import HeaderSettings from '~/components/general/HeaderSettings.vue';
import HeaderPaymentsAndCurrencies from '~/components/general/HeaderPaymentsAndCurrencies.vue';
import HeaderTransactions from '~/components/general/HeaderTransactions.vue';
import HeaderMerchants from '~/components/general/HeaderMerchants.vue';
import { getStorageIsDarkTheme } from '~/localStorage';

export default {
  components: {
    HeaderUser,
    HeaderFinance,
    HeaderStatistics,
    HeaderP2P,
    HeaderTraders,
    HeaderSettings,
    HeaderPaymentsAndCurrencies,
    HeaderTransactions,
    HeaderMerchants,
  },

  data() {
    return {
      setThemeProcessing: false,
    };
  },

  computed: {
    ...mapState('common', [
      'showNavigation',
      'generalLoader',
      'generalProgress',
      'adminSettings',
    ]),

    isDarkTheme() {
      const { isDarkTheme } = this.adminSettings;
      return isDarkTheme;
    },

    locale() {
      const { locale } = this.adminSettings;
      return locale;
    },

    header() {
      const { header } = this.$route.meta;
      if (header) {
        return header;
      }
      const { name } = this.$route;
      return {
        type: 'text',
        value: name[0].toUpperCase() + name.slice(1),
      };
    },
  },

  watch: {
    isDarkTheme(value) {
      this.$vuetify.theme.dark = value;
    },
    locale(value) {
      this.$i18n.locale = value;
    },
  },

  created() {
    // set theme from localStorage until currentAdmin is loaded
    // this will save from unnecessary theme flickering at the beginning of the page load
    this.$vuetify.theme.dark = getStorageIsDarkTheme();
  },

  methods: {
    ...mapActions('common', [
      'setShowNavigation',
      'setIsDarkTheme',
      'setLocale',
    ]),

    toggleShowNavigation() {
      const { showNavigation } = this;
      this.setShowNavigation(!showNavigation);
    },

    async toggleTheme() {
      const { isDarkTheme } = this;
      this.setThemeProcessing = true;
      await this.setIsDarkTheme(!isDarkTheme);
      this.setThemeProcessing = false;
    },

    async changeLocaleHandler(value) {
      this.setThemeProcessing = true;
      await this.setLocale(value);
      this.setThemeProcessing = false;
    },
  },
};
