export default [
  {
    path: '',
    name: 'transactions',
    component: () => import(/* webpackChunkName: "transactions-history" */ '~/views/TransactionsHistory/TransactionsHistory.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderTransactions' },
    },
  },

  {
    path: 'statistics',
    name: 'transactions-statistics',
    component: () => import(/* webpackChunkName: "transactions-statistics" */ '~/views/TransactionsStatistics/TransactionsStatistics.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderTransactions' },
      rootRouteName: 'transactions',
    },
  },

];
