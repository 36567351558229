import http from '~/http/new';

export default ({
  state: {
    data: {},
    personalDataRecord: {},
  },

  getters: {},

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PERSONAL_DATA(state, payload) {
      state.personalDataRecord = {
        ...state.personalDataRecord,
        [payload.userId]: payload.data,
      };
    },
  },

  actions: {
    async loadData({ commit }) {
      const response = await http.get('v1/p2p/fee');
      const { result, ...data } = response.data;
      if (result?.Success) {
        commit('SET_DATA', data);
      }
    },

    async editFees(__store, data) {
      const response = await http.put('v1/p2p/fee', data);
      if (!response?.data?.result?.Success) {
        throw new Error(`The fees has not been edited because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async loadPersonalFeeDataById({ commit }, { userId }) {
      const response = await http.get('v1/p2p/fee/profile', { params: { userId } });
      const { profiles } = response.data;
      commit('SET_PERSONAL_DATA', { userId, data: profiles?.[0]?.settings ?? [] });

      return response.data;
    },

    async editPersonalFees(__store, data) {
      const response = await http.put('v1/p2p/fee/profile', data);
      if (!response?.data?.result?.Success) {
        throw new Error(`Personal fees has not been edited because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },
  },

  namespaced: true,
});
