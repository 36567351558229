//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      prompt: '',
    };
  },

  computed: {
    ...mapState('common', ['generalConfirm']),

    model: {
      get() {
        const { showModal } = this.generalConfirm;
        return showModal;
      },
      set(value) {
        this.setGeneralConfirmModal(value);
        if (!value) {
          this.callGeneralConfirmCancelCallback();
        }
      },
    },

    isOkDisabled() {
      const { promptRequired } = this.generalConfirm;
      const { prompt } = this;

      if (!promptRequired) return false;
      return prompt.length === 0;
    },
  },

  watch: {
    async model(value) {
      await this.$nextTick();
      if (value) this.$refs.okButton.$el.focus();
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralConfirmModal',
      'callGeneralConfirmCallback',
      'callGeneralConfirmCancelCallback',
    ]),

    confirm() {
      const { prompt } = this;
      this.setGeneralConfirmModal(false);
      this.callGeneralConfirmCallback({ prompt });
    },
  },
};
