import http from '~/http/index';
import checkConstAvailability from '~/utils/check-const-availability';

export default ({
  state: {
    notificationTypeList: [
      {
        id: 0,
        type: 'SignIn',
        caption: 'SignIn',
      },
      {
        id: 1,
        type: 'SignUp',
        caption: 'SignUp',
      },
      {
        id: 2,
        type: 'UnknownIp',
        caption: 'UnknownIp',
      },
      {
        id: 3,
        type: 'ChagePassword',
        caption: 'ChagePassword',
      },
      {
        id: 4,
        type: 'Change2FA',
        caption: 'Change2FA',
      },
      {
        id: 5,
        type: 'CreateApiKey',
        caption: 'CreateApiKey',
      },
      {
        id: 6,
        type: 'ChangeApiKey',
        caption: 'ChangeApiKey',
      },
      {
        id: 7,
        type: 'ChangeIpWhitelist',
        caption: 'ChangeIpWhitelist',
      },
      {
        id: 8,
        type: 'NewDeposit',
        caption: 'NewDeposit',
      },
      {
        id: 9,
        type: 'ChangeDeposit',
        caption: 'ChangeDeposit',
      },
      {
        id: 10,
        type: 'CompleteDeposit',
        caption: 'CompleteDeposit',
      },
      {
        id: 11,
        type: 'NewWithdrawal',
        caption: 'NewWithdrawal',
      },
      {
        id: 12,
        type: 'WithdrawalInBlockchain',
        caption: 'WithdrawalInBlockchain',
      },
      {
        id: 13,
        type: 'ChangeWithdrawal',
        caption: 'ChangeWithdrawal',
      },
      {
        id: 14,
        type: 'CompleteWithdrawal',
        caption: 'CompleteWithdrawal',
      },
      {
        id: 15,
        type: 'CancelWithdrawal',
        caption: 'CancelWithdrawal',
      },
      {
        id: 16,
        type: 'NewCode',
        caption: 'NewCode',
      },
      {
        id: 17,
        type: 'RedeemedCode',
        caption: 'RedeemedCode',
      },
      {
        id: 18,
        type: 'UserRedeemCode',
        caption: 'UserRedeemCode',
      },
      {
        id: 19,
        type: 'CancelCode',
        caption: 'CancelCode',
      },
      {
        id: 20,
        type: 'NewPrivateMessage',
        caption: 'NewPrivateMessage',
      },
      {
        id: 21,
        type: 'NewTicket',
        caption: 'NewTicket',
      },
      {
        id: 22,
        type: 'AnswerTicket',
        caption: 'AnswerTicket',
      },
      {
        id: 23,
        type: 'News',
        caption: 'News',
      },
      {
        id: 24,
        type: 'Analitics',
        caption: 'Analitics',
      },
      {
        id: 25,
        type: 'CreateReferralProgram',
        caption: 'CreateReferralProgram',
      },
      {
        id: 26,
        type: 'ChangeReferralProgram',
        caption: 'ChangeReferralProgram',
      },
      {
        id: 27,
        type: 'FillOrder',
        caption: 'FillOrder',
      },
      {
        id: 28,
        type: 'VerificationComplete',
        caption: 'VerificationComplete',
      },
      {
        id: 29,
        type: 'VerificationDeclined',
        caption: 'VerificationDeclined',
      },
      {
        id: 30,
        type: 'P2PNotification',
        caption: 'P2PNotification',
      },
      {
        id: 31,
        type: 'APINotifications',
        caption: 'APINotifications',
      },
      {
        id: 32,
        type: 'P2PArbitrNotification',
        caption: 'P2PArbitrNotification',
      },
      {
        id: 33,
        type: 'P2PAddUserToTrusted',
        caption: 'P2PAddUserToTrusted',
      },
      {
        id: 34,
        type: 'P2PNewComment',
        caption: 'P2PNewComment',
      },
      {
        id: 35,
        type: 'NewGroupChatMessage',
        caption: 'NewGroupChatMessage',
      },
      {
        id: 36,
        type: 'P2PEnableLimit',
        caption: 'P2PEnableLimit',
      },
      {
        id: 37,
        type: 'P2PChangeLimit',
        caption: 'P2PChangeLimit',
      },
      {
        id: 38,
        type: 'P2PCreateOffer',
        caption: 'P2PCreateOffer',
      },
      {
        id: 39,
        type: 'P2PStatusOffer',
        caption: 'P2PStatusOffer',
      },
      {
        id: 40,
        type: 'P2PStartTrade',
        caption: 'P2PStartTrade',
      },
      {
        id: 41,
        type: 'P2PConfirmSendMoney',
        caption: 'P2PConfirmSendMoney',
      },
      {
        id: 42,
        type: 'P2PConfirmReceiveMoney',
        caption: 'P2PConfirmReceiveMoney',
      },
      {
        id: 43,
        type: 'P2PTradeResult',
        caption: 'P2PTradeResult',
      },
      {
        id: 44,
        type: 'P2PTradeClose',
        caption: 'P2PTradeClose',
      },
      {
        id: 45,
        type: 'TelegramIdSet',
        caption: 'TelegramIdSet',
      },
      {
        id: 46,
        type: 'TelegramIdRemove',
        caption: 'TelegramIdRemove',
      },
      {
        id: 47,
        type: 'ConfirmationCodeRateLimit',
        caption: 'ConfirmationCodeRateLimit',
      },
      {
        id: 48,
        type: 'TwoFaRateLimit',
        caption: 'TwoFaRateLimit',
      },
      {
        id: 49,
        type: 'DeclineDeposit',
        caption: 'DeclineDeposit',
      },
      {
        id: 50,
        type: 'DeclineWithdrawal',
        caption: 'DeclineWithdrawal',
      },
      {
        id: 51,
        type: 'TransactionNeedContactSupport',
        caption: 'TransactionNeedContactSupport',
      },
      {
        id: 52,
        type: 'P2PTradeTerminated',
        caption: 'P2PTradeTerminated',
      },
      {
        id: 53,
        type: 'P2PBlockUser',
        caption: 'P2PBlockUser',
      },
      {
        id: 54,
        type: 'InternalDeposit',
        caption: 'InternalDeposit',
      },
      {
        id: 60,
        type: 'StoreOnModeration',
        caption: 'StoreOnModeration',
      },
      {
        id: 61,
        type: 'StoreModerationFinished',
        caption: 'StoreModerationFinished',
      },
      {
        id: 60,
        type: 'MerchantOrder',
        caption: 'MerchantOrder',
      },
      {
        id: 61,
        type: 'MerchantTransaction',
        caption: 'MerchantTransaction',
      },
    ].filter((e, _i, arr) => checkConstAvailability(e, {
      NOTIFICATION_TYPE_LIST: Object.fromEntries(arr.map((item) => [item.type, item.type])),
    }, (elem) => elem.type)),
    notificationServiceTypeList: [
      {
        id: 0,
        type: 'WS',
        caption: 'WS',
      },
      {
        id: 1,
        type: 'System',
        caption: 'System',
      },
      {
        id: 2,
        type: 'Email',
        caption: 'Email',
      },
      {
        id: 3,
        type: 'Telegram',
        caption: 'Telegram',
      },
      {
        id: 5,
        type: 'Sound',
        caption: 'Sound',
      },
      {
        id: 6,
        type: 'Push',
        caption: 'Push',
      },
    ].filter((e, _i, arr) => checkConstAvailability(e, {
      NOTIFICATION_SERVICE_TYPE_LIST: Object.fromEntries(arr.map((item) => [item.type, item.type])),
    }, (elem) => elem.type)),
    notificationGroupList: [
      {
        group: 'security',
        caption: 'Security',
        notificationIds: [0, 1, 2, 3, 4, 7, 27, 48],
      },
      {
        group: 'orders',
        caption: 'Orders',
        notificationIds: [26],
      },
      {
        group: 'transactions',
        caption: 'Transactions',
        notificationIds: [8, 9, 10, 11, 12, 13, 14, 15, 49, 50, 51, 54],
      },
      {
        group: 'codes',
        caption: 'Codes',
        notificationIds: [16, 17, 18, 19, 47],
      },
      {
        group: 'system',
        caption: 'System',
        notificationIds: [5, 6, 20, 21, 22, 23, 24, 25, 28, 29, 31, 32, 45, 46],
      },
      {
        group: 'p2p',
        caption: 'P2P',
        notificationIds: [30, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 52, 53],
      },
      {
        group: 'merchants',
        caption: 'Merchants',
        notificationIds: [60, 61, 62, 63],
      },
    ].filter((e, _i, arr) => checkConstAvailability(e, {
      NOTIFICATION_GROUP_LIST: Object.fromEntries(arr.map((item) => [item.group, item.group])),
    }, (elem) => elem.group)),
    userNotificationSettings: [],
  },

  getters: {
  },

  mutations: {
    SET_USER_NOTIFICATION_SETTINGS(state, data) {
      state.userNotificationSettings = data;
    },
  },

  actions: {
    async getUserNotificationSettings({ commit, rootState }) {
      const { id } = rootState.user.user;
      const response = await http.get('User/GetUserNotificationSettings', {
        params: { UserId: id },
      });
      const { notificationList } = response.data;
      commit('SET_USER_NOTIFICATION_SETTINGS', notificationList);
    },

    setUserNotificationSettings({ commit }, data) {
      commit('SET_USER_NOTIFICATION_SETTINGS', data);
    },

    async updateUserNotificationsSettings({ state, rootState }) {
      const { id } = rootState.user.user;
      const { userNotificationSettings } = state;
      const payload = {
        userId: id,
        commands: userNotificationSettings.map((e) => ({
          notification: e.notification,
          service: e.notificationServiceType,
          ignore: e.ignored,
        })),
      };
      await http.post('User/UpdateUserNotificationsSettings', payload);
    },

    async updateUserEmailBodyType(__store, data) {
      await http.put('v2/User/UpdateUserEmailBodyType', data);
    },
  },

  namespaced: true,
});
