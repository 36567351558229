import http from '~/http/index';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    appList: [],
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      return filters;
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_APP_LIST(state, data) {
      state.appList = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('v2/FrontConfig/groups', {
        params: {
          ...getters.requestData,
          ...data,
        },
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async loadAppList({ commit }) {
      const limit = 50;
      let arr = [];
      const foo = async function (offset = 0) {
        const response = await http.get('v2/FrontConfig', {
          params: {
            offset,
            limit,
          },
        });
        const { items } = response.data;
        arr = [...arr, ...items];
        if (items.length === limit) {
          await foo(offset + limit);
        } else {
          commit('SET_APP_LIST', arr);
        }
      };
      await foo();
    },

    async getGroup(__store, params) {
      const { groupId } = params;
      const response = await http.get(`v2/FrontConfig/groups/${groupId}`);
      return response.data;
    },

    async createGroup(__store, params) {
      const response = await http.post('v2/SecurityGroups/CreateGroup', null, { params });
      return response.data;
    },

    async updateGroup(__store, params) {
      const response = await http.put('v2/SecurityGroups/UpdateGroup', null, { params });
      return response.data;
    },

    async removeGroup(__store, params) {
      const response = await http.delete('v2/SecurityGroups/RemoveGroup', { params });
      return response.data;
    },

    async addUserToGroup(__store, params) {
      const response = await http.post('v2/SecurityGroups/AddUserToGroup', null, { params });
      return response.data;
    },

    async removeUserFromGroup(__store, params) {
      const response = await http.delete('v2/SecurityGroups/RemoveUserFromGroup', { params });
      return response.data;
    },

    async getUserGroups(__store, params) {
      const response = await http.get('v2/SecurityGroups/GetUserGroups', { params });
      return response.data;
    },

    async getGroupUsers(__store, params) {
      const response = await http.get('v2/SecurityGroups/GetGroupUsers', { params });
      return response.data;
    },

    async updateApp(__store, { params, data }) {
      const { appId } = params;
      const response = await http.put(`v2/FrontConfig/apps/${appId}`, data, { params });
      return response.data;
    },

    async deny(__store, params) {
      const { appId, groupId } = params;
      const response = await http.put(`v2/FrontConfig/apps/${appId}/deny/${groupId}`, null, { params });
      return response.data;
    },

    async allow(__store, params) {
      const { appId, groupId } = params;
      const response = await http.put(`v2/FrontConfig/apps/${appId}/allow/${groupId}`, null, { params });
      return response.data;
    },

    async reset(__store, params) {
      const { appId, groupId } = params;
      const response = await http.delete(`v2/FrontConfig/apps/${appId}/reset/${groupId}`, { params });
      return response.data;
    },
  },

  namespaced: true,
});
