import axios from 'axios';
import qs from 'qs';
import { getToken, clearToken } from '~/localStorage';

const requestHandler = (request) => {
  const accessToken = getToken();
  const result = Object.assign(request);
  if (accessToken) {
    result.headers['authorization-vbtc'] = accessToken;
  }
  return result;
};

const errorHandler = (error) => Promise.reject(error.response.data);

const successHandler = (response) => {
  const { error } = response.data;
  if (!error) {
    return response;
  }
  if (error.statusCode === 401) {
    clearToken();
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

const http = axios.create({
  // TODO: fix this
  baseURL: process.env.VUE_APP_P2PAY_API_URL || process.env.VUE_APP_API_URL.replace('api/bo/', ''),
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
});

http.interceptors.request.use(
  (request) => requestHandler(request),
);

http.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export default http;
