export default [
  {
    path: '',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "statistics-systemStatus" */ '~/views/StatisticsSystemStatus/StatisticsSystemStatus.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
  {
    path: 'gross-profit',
    name: 'statistics-gross-profit',
    component: () => import(/* webpackChunkName: "statistics-grossProfit" */ '~/views/StatisticsGrossProfit/StatisticsGrossProfit.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
  {
    path: 'withdrawal-deposit-turnover',
    name: 'statistics-wd-turnover',
    component: () => import(/* webpackChunkName: "statistics-wdTurnover" */ '~/views/StatisticsWdTurnover/StatisticsWdTurnover.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
  {
    path: 'trade-turnover',
    name: 'statistics-trade-turnover',
    component: () => import(/* webpackChunkName: "statistics-tradeTurnover" */ '~/views/StatisticsTradeTurnover/StatisticsTradeTurnover.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
  {
    path: 'referral-program',
    name: 'statistics-ref-program',
    component: () => import(/* webpackChunkName: "statistics-referralProgram" */ '~/views/StatisticsReferralProgram/StatisticsReferralProgram.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
  {
    path: 'staking',
    name: 'statistics-staking',
    component: () => import(/* webpackChunkName: "statistics-staking" */ '~/views/StatisticsStaking/StatisticsStaking.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
  {
    path: 'users',
    name: 'statistics-users',
    component: () => import(/* webpackChunkName: "statistics-users" */ '~/views/StatisticsUsers/StatisticsUsers.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderStatistics' },
    },
  },
];
