import checkConstAvailability from '~/utils/check-const-availability';

const OPERATION_SIDE = {
  IN: 0,
  OUT: 1,
  UNDEFINED: 2,
};

const OPERATION_SIDE_LIST = [
  {
    id: OPERATION_SIDE.IN,
    caption: 'In',
  },
  {
    id: OPERATION_SIDE.OUT,
    caption: 'Out',
  },
  // {
  //   id: OPERATION_SIDE.UNDEFINED,
  //   caption: 'Undefined',
  // },
];

const OPERATION_TYPE = {
  UNDEFINED: 0,
  TRADE: 1,
  TRANSACTION: 2,
  REFERRAL: 3,
  P2P: 4,
  ORDER: 5,
  PARAMINING: 6,
  FEE: 7,
  TRANSACTION_FEE: 8,
  P2P_FEE: 9,
  TRANSACTION_INTERNAL: 10,
  TRANSACTION_INTERNAL_FEE: 11,
  CONVERT: 12,
  ADMIN_TRANSACTIONS: 13,
};

const OPERATION_TYPE_LIST = [
  // {
  //   id: OPERATION_TYPE.UNDEFINED,
  //   caption: 'Undefined',
  // },
  {
    id: OPERATION_TYPE.TRADE,
    caption: 'Trade',
  },
  {
    id: OPERATION_TYPE.TRANSACTION,
    caption: 'Deposit/withdrawal',
  },
  {
    id: OPERATION_TYPE.ADMIN_TRANSACTIONS,
    caption: 'Admin Transactions',
  },
  {
    id: OPERATION_TYPE.REFERRAL,
    caption: 'Referral',
  },
  {
    id: OPERATION_TYPE.P2P,
    caption: 'P2P',
  },
  // {
  //   id: OPERATION_TYPE.ORDER,
  //   caption: 'Order',
  // },
  {
    id: OPERATION_TYPE.PARAMINING,
    caption: 'Staking',
  },
  {
    id: OPERATION_TYPE.TRANSACTION_INTERNAL,
    caption: 'Internal/code',
  },
  {
    id: OPERATION_TYPE.FEE,
    caption: 'Fee',
  },
  {
    id: OPERATION_TYPE.TRANSACTION_FEE,
    caption: 'Deposit/withdrawal fee',
  },
  {
    id: OPERATION_TYPE.P2P_FEE,
    caption: 'P2P fee',
  },
  {
    id: OPERATION_TYPE.TRANSACTION_INTERNAL_FEE,
    caption: 'Internal/code fee',
  },
  {
    id: OPERATION_TYPE.CONVERT,
    caption: 'Convert',
  },
].filter((e) => checkConstAvailability(e, { OPERATION_TYPE }));

const TRANSACTION_TYPE = {
  DEPOSIT: 0,
  WITHDRAWAL: 1,
};

const TRANSACTION_TYPE_LIST = [
  {
    id: TRANSACTION_TYPE.DEPOSIT,
    caption: 'Deposit',
  },
  {
    id: TRANSACTION_TYPE.WITHDRAWAL,
    caption: 'Withdrawal',
  },
];

const TRANSACTION_METHOD_TYPE = {
  UNDEFINED: 0,
  CREDIT_CARD: 7,
  ADV_CASH: 8,
  CODE: 12,
  MANUAL: 23,
  BLOCKCHAIN: 24,
  ADMIN: 25,
  CRYPTOMAT_FEE: 26,
  PAYMENT: 27,
  INTERNAL: 28,
};

const TRANSACTION_METHOD_TYPE_LIST = [
  // {
  //   id: TRANSACTION_METHOD_TYPE.UNDEFINED,
  //   caption: 'Undefined',
  // },
  {
    id: TRANSACTION_METHOD_TYPE.CREDIT_CARD,
    caption: 'Credit card',
  },
  {
    id: TRANSACTION_METHOD_TYPE.ADV_CASH,
    caption: 'AdvCash',
  },
  {
    id: TRANSACTION_METHOD_TYPE.CODE,
    caption: 'Code',
  },
  {
    id: TRANSACTION_METHOD_TYPE.MANUAL,
    caption: 'Manual',
  },
  {
    id: TRANSACTION_METHOD_TYPE.BLOCKCHAIN,
    caption: 'Blockchain',
  },
  {
    id: TRANSACTION_METHOD_TYPE.ADMIN,
    caption: 'Admin',
  },
  {
    id: TRANSACTION_METHOD_TYPE.CRYPTOMAT_FEE,
    caption: 'Cryptomat fee',
  },
  {
    id: TRANSACTION_METHOD_TYPE.PAYMENT,
    caption: 'Payment',
  },
  {
    id: TRANSACTION_METHOD_TYPE.INTERNAL,
    caption: 'Internal',
  },
].filter((e) => checkConstAvailability(e, { TRANSACTION_METHOD_TYPE }));

const TRANSACTION_STATUS = {
  AWAITING_CONFIRMATION: 0,
  // Awaiting confirmation from remote service. Money sent
  PROCESSING: 1,
  // Awaiting processing by remote service. Money sent
  COMPLETED: 2,
  // All ok
  CANCELLED: 3,
  // Deleted (only for AwaitingConfirmation or AwaitingManualCheck)
  DECLINED: 4,
  // Error
  ADMIN_CHECK: 5,
  // Need to check (error, etc.). Money in frozen
  IN_QUEUE: 7,
  // Transaction in queue
  AWAITING_MANUAL_CHECK: 8,
  // Awaiting admin for manual deposit and withdrawal. Money in frozen
  AWAITING_USER_CONFIRMATION: 9,
  // Waiting user to send confirmation code that has been sent to his email. Money in frozen
  ADMIN_PROCESSING: 10,
  // The administrator processes the transaction
  NEED_CONTACT_SUPPORT: 11,
  // Need contact support
};

const TRANSACTION_STATUS_LIST = [
  {
    id: TRANSACTION_STATUS.AWAITING_CONFIRMATION,
    caption: 'Awaiting confirmation',
  },
  {
    id: TRANSACTION_STATUS.PROCESSING,
    caption: 'Processing',
  },
  {
    id: TRANSACTION_STATUS.COMPLETED,
    caption: 'Completed',
  },
  {
    id: TRANSACTION_STATUS.CANCELLED,
    caption: 'Cancelled',
  },
  {
    id: TRANSACTION_STATUS.DECLINED,
    caption: 'Declined',
  },
  {
    id: TRANSACTION_STATUS.ADMIN_CHECK,
    caption: 'Admin check',
  },
  {
    id: TRANSACTION_STATUS.IN_QUEUE,
    caption: 'In queue',
  },
  {
    id: TRANSACTION_STATUS.AWAITING_MANUAL_CHECK,
    caption: 'Awaiting manual check',
  },
  {
    id: TRANSACTION_STATUS.AWAITING_USER_CONFIRMATION,
    caption: 'Awaiting user confirmation',
  },
  {
    id: TRANSACTION_STATUS.ADMIN_PROCESSING,
    caption: 'Admin processing',
  },
  {
    id: TRANSACTION_STATUS.NEED_CONTACT_SUPPORT,
    caption: 'Need contact support',
  },
].filter((e) => checkConstAvailability(e, { TRANSACTION_STATUS }));

const SOURCE_TYPE = {
  UNDEFINED: 0,
  SITE: 1,
  MOBILE: 2,
  PUBLIC: 3,
  TELEGRAM: 4,
  CRYPTOMAT: 5,
  PAYMENT: 6,
};

const SOURCE_TYPE_LIST = [
  // {
  //   id: SOURCE_TYPE.UNDEFINED,
  //   caption: 'Undefined',
  // },
  {
    id: SOURCE_TYPE.SITE,
    caption: 'Site',
  },
  {
    id: SOURCE_TYPE.MOBILE,
    caption: 'Mobile',
  },
  {
    id: SOURCE_TYPE.PUBLIC,
    caption: 'Public',
  },
  {
    id: SOURCE_TYPE.TELEGRAM,
    caption: 'Telegram',
  },
  {
    id: SOURCE_TYPE.CRYPTOMAT,
    caption: 'Cryptomat',
  },
  {
    id: SOURCE_TYPE.PAYMENT,
    caption: 'Payment',
  },
].filter((e) => checkConstAvailability(e, { SOURCE_TYPE }));

const REQUISITES_STATUS = {
  NEW: 0,
  VALIDATED: 1,
  ATTENTION: 2,
};

const REQUISITES_STATUS_LIST = [
  {
    id: REQUISITES_STATUS.NEW,
    caption: 'New',
  },
  {
    id: REQUISITES_STATUS.VALIDATED,
    caption: 'Approved',
  },
  {
    id: REQUISITES_STATUS.ATTENTION,
    caption: 'Problematic',
  },
].filter((e) => checkConstAvailability(e, { REQUISITES_STATUS }));

const CODE_STATUS = {
  ACTIVE: 0,
  EXPIRED: 1,
  REDEEMED: 2,
  UNCONFIRMED: 3,
  CANCELED: 4,
  MERGED: 5,
  BLOCKED: 6,
  DEVIDED: 7,
};

const CODE_STATUS_LIST = [
  {
    id: CODE_STATUS.ACTIVE,
    caption: 'Active',
  },
  {
    id: CODE_STATUS.EXPIRED,
    caption: 'Expired',
  },
  {
    id: CODE_STATUS.REDEEMED,
    caption: 'Redeemed',
  },
  {
    id: CODE_STATUS.UNCONFIRMED,
    caption: 'Unconfirmed',
  },
  {
    id: CODE_STATUS.CANCELED,
    caption: 'Canceled',
  },
  {
    id: CODE_STATUS.MERGED,
    caption: 'Merged',
  },
  {
    id: CODE_STATUS.BLOCKED,
    caption: 'Blocked',
  },
  {
    id: CODE_STATUS.DEVIDED,
    caption: 'Devided',
  },
].filter((e) => checkConstAvailability(e, { CODE_STATUS }));

export default {
  OPERATION_SIDE,
  OPERATION_SIDE_LIST,
  OPERATION_TYPE,
  OPERATION_TYPE_LIST,
  TRANSACTION_METHOD_TYPE,
  TRANSACTION_METHOD_TYPE_LIST,
  TRANSACTION_TYPE,
  TRANSACTION_TYPE_LIST,
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_LIST,
  SOURCE_TYPE,
  SOURCE_TYPE_LIST,
  REQUISITES_STATUS,
  REQUISITES_STATUS_LIST,
  CODE_STATUS,
  CODE_STATUS_LIST,
};
