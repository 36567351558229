import http from '~/http/new';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const {
        filters,
        // sort,
        page,
      } = state;
      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value || value === 0 || value === '0') {
          acc[key] = value;
        }
        return acc;
      }, {});
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...formattedFilters,
        createdFrom: formattedFilters.createdFrom ? new Date(formattedFilters.createdFrom).getTime() / 1000 : undefined,
        createdTo: formattedFilters.createdTo ? new Date(formattedFilters.createdTo).getTime() / 1000 + 86399 : undefined,
        // ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        // getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('v1/transactions', {
        params: {
          ...getters.requestData,
          ...data,
        },
      });
      const { result, items, total } = response.data;
      if (result?.Success) {
        commit('SET_DATA', items);
        if (total) {
          const pages = Math.ceil(total / itemsPerPage);
          commit('SET_PAGES', pages);
        }
      }
    },

    async confirmTransaction(__store, data) {
      const response = await http.put(`v1/transactions/${data.id}/confirm`, data);
      if (!response?.data?.result?.Success) {
        throw new Error(`Transaction has not been confirmed because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async completeTransaction(__store, data) {
      const response = await http.put(`v1/transactions/${data.id}/complete`, data);
      if (!response?.data?.result?.Success) {
        throw new Error(`Transaction has not been completed because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },

    async cancelTransaction(__store, data) {
      const response = await http.put(`v1/transactions/${data.id}/cancel`, data);
      if (!response?.data?.result?.Success) {
        throw new Error(`Transaction has not been cancelled because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },
  },

  namespaced: true,
});
