import checkConstAvailability from '~/utils/check-const-availability';

const DOCUMENT_TYPE = {
  PASSPORT: 0,
  INTERNATIONAL_PASSPORT: 1,
  DRIVER_LICENSE: 2,
};

const DOCUMENT_TYPE_LIST = [
  {
    id: DOCUMENT_TYPE.PASSPORT,
    caption: 'Passport',
  },
  {
    id: DOCUMENT_TYPE.INTERNATIONAL_PASSPORT,
    caption: 'International passport',
  },
  {
    id: DOCUMENT_TYPE.DRIVER_LICENSE,
    caption: 'Driver license',
  },
].filter((e) => checkConstAvailability(e, { DOCUMENT_TYPE }));

const VERIFICATION_STATUS = {
  UNVERIFIED: 0,
  IN_PROCESS: 1,
  REQUIRES_DATA: 2,
  VERIFIED: 3,
  VERIFICATION_REQUEST: 4,
  VERIFIED_VERIFICATION_REQUEST: 5,
  REJECTED: 6,
};

const VERIFICATION_STATUS_LIST = [
  {
    id: VERIFICATION_STATUS.UNVERIFIED,
    caption: 'Unverified',
  },
  {
    id: VERIFICATION_STATUS.IN_PROCESS,
    caption: 'In process',
  },
  {
    id: VERIFICATION_STATUS.REQUIRES_DATA,
    caption: 'Requires data',
  },
  {
    id: VERIFICATION_STATUS.VERIFIED,
    caption: 'Verified',
  },
  {
    id: VERIFICATION_STATUS.VERIFICATION_REQUEST,
    caption: 'Verification request',
  },
  {
    id: VERIFICATION_STATUS.VERIFIED_VERIFICATION_REQUEST,
    caption: 'Verified verification request',
  },
  {
    id: VERIFICATION_STATUS.REJECTED,
    caption: 'Rejected',
  },
].filter((e) => checkConstAvailability(e, { VERIFICATION_STATUS }));

const VERIFICATION_RESPONSE_TYPE = {
  VERIFIED: 0,
  REJECTED: 1,
  REQUIRED_DATA: 2,
  RESET: 3,
};

const VERIFICATION_RESPONSE_TYPE_LIST = [
  {
    id: VERIFICATION_RESPONSE_TYPE.VERIFIED,
    caption: 'Verified',
  },
  {
    id: VERIFICATION_RESPONSE_TYPE.REJECTED,
    caption: 'Rejected',
  },
  {
    id: VERIFICATION_RESPONSE_TYPE.REQUIRED_DATA,
    caption: 'Required data',
  },
  {
    id: VERIFICATION_RESPONSE_TYPE.RESET,
    caption: 'Reset',
  },
].filter((e) => checkConstAvailability(e, { VERIFICATION_RESPONSE_TYPE }));

const AUTO_VERIFICATION_STATUS = {
  IN_QUEUE: 0,
  IN_PROCESS: 1,
  VERIFIED: 2,
  REJECTED: 3,
  NEED_DATA: 4,
  ERROR: 5,
};

const AUTO_VERIFICATION_STATUS_LIST = [
  {
    id: AUTO_VERIFICATION_STATUS.IN_QUEUE,
    caption: 'In Queue',
  },
  {
    id: AUTO_VERIFICATION_STATUS.IN_PROCESS,
    caption: 'In Process',
  },
  {
    id: AUTO_VERIFICATION_STATUS.VERIFIED,
    caption: 'Verified',
  },
  {
    id: AUTO_VERIFICATION_STATUS.REJECTED,
    caption: 'Rejected',
  },
  {
    id: AUTO_VERIFICATION_STATUS.NEED_DATA,
    caption: 'Need Data',
  },
  {
    id: AUTO_VERIFICATION_STATUS.ERROR,
    caption: 'Error',
  },
].filter((e) => checkConstAvailability(e, { AUTO_VERIFICATION_STATUS }));

const USER_ACTION_TYPE = {
  SIGN_IN: 0,
  PASSWORD: 1,
  API_KEY: 2,
  IP_WHITELIST: 3,
  NFA: 4,
  NFA_CODE: 5,
  LOCK: 6,
  TELEGRAM_ID: 8,
};

const USER_ACTION_TYPE_LIST = [
  {
    id: USER_ACTION_TYPE.SIGN_IN,
    caption: 'Sign in',
  },
  {
    id: USER_ACTION_TYPE.PASSWORD,
    caption: 'Password',
  },
  {
    id: USER_ACTION_TYPE.API_KEY,
    caption: 'Api key',
  },
  {
    id: USER_ACTION_TYPE.IP_WHITELIST,
    caption: 'Ip whitelist',
  },
  {
    id: USER_ACTION_TYPE.NFA,
    caption: 'Mfa',
  },
  {
    id: USER_ACTION_TYPE.NFA_CODE,
    caption: 'Mfa code',
  },
  {
    id: USER_ACTION_TYPE.LOCK,
    caption: 'Lock',
  },
  {
    id: USER_ACTION_TYPE.TELEGRAM_ID,
    caption: 'Telegram Bot',
  },
].filter((e) => checkConstAvailability(e, { USER_ACTION_TYPE }));

const EMAIL_BODY_TYPE = {
  HTML: 0,
  PLAIN_TEXT: 1,
};

const EMAIL_BODY_TYPE_LIST = [
  {
    id: EMAIL_BODY_TYPE.HTML,
    caption: 'HTML',
  },
  {
    id: EMAIL_BODY_TYPE.PLAIN_TEXT,
    caption: 'Plain text',
  },
].filter((e) => checkConstAvailability(e, { EMAIL_BODY_TYPE }));

const REFERRAL_INCOMES_TYPE = {
  TRADE: 0,
  P2P: 1,
  PARAMINING: 2,
};

const REFERRAL_INCOMES_TYPE_LIST = [
  {
    id: REFERRAL_INCOMES_TYPE.TRADE,
    caption: 'Trade',
  },
  {
    id: REFERRAL_INCOMES_TYPE.P2P,
    caption: 'P2P',
  },
  {
    id: REFERRAL_INCOMES_TYPE.PARAMINING,
    caption: 'Staking',
  },
].filter((e) => checkConstAvailability(e, { REFERRAL_INCOMES_TYPE }));

const GENDER = {
  UNKNOWN: 0,
  MALE: 1,
  FEMALE: 2,
};

const GENDER_LIST = [
  {
    id: GENDER.UNKNOWN,
    caption: 'Unknown',
  },
  {
    id: GENDER.MALE,
    caption: 'Male',
  },
  {
    id: GENDER.FEMALE,
    caption: 'Female',
  },
].filter((e) => checkConstAvailability(e, { GENDER }));

const NFA_CODE_TYPE = {
  SIGN_IN: 0,
  IP_WHITELIST: 1,
  API_KEY: 2,
  SET_MFA: 3,
  UNSET_MFA: 4,
  CONFIRM_TRANSACTION: 5,
  CHANGE_PASSWORD: 6,
  CONFIRM_P2P_TRANSACTION: 7,
  RECOVER_PASSWORD: 8,
  CONFIRM_GROUP_CHAT: 9,
  CONFIRM_CODE: 10,
  TELEGRAM_BOT_CLAIMS: 11,
  TELEGRAM_BOT_UNTIE: 12,
  TELEGRAM_BOT_TIE: 13,
  MFA_UPDATE: 14,
  SET_RECOVERY_KEY: 15,
  UNSET_RECOVERY_KEY: 16,
  ACCESS_RECOVERY: 17,
  ANTIPHISHING: 18,
  SESSION_SETTINGS: 19,
  ALLOWED_ADDRESS: 20,
  NOTIFICATION: 21,
  SET_EMAIL_ENCRYPT: 22,
  UNSET_EMAIL_ENCRYPT: 23,
  EDIT_RECOVERY_KEY: 24,
  ENABLE_MFA: 25,
};

const NFA_CODE_TYPE_LIST = [
  {
    id: NFA_CODE_TYPE.SIGN_IN,
    caption: 'Sign in',
  },
  {
    id: NFA_CODE_TYPE.IP_WHITELIST,
    caption: 'Ip whitelist',
  },
  {
    id: NFA_CODE_TYPE.API_KEY,
    caption: 'Api key',
  },
  {
    id: NFA_CODE_TYPE.SET_MFA,
    caption: 'Set MFA',
  },
  {
    id: NFA_CODE_TYPE.UNSET_MFA,
    caption: 'Unset MFA',
  },
  {
    id: NFA_CODE_TYPE.CONFIRM_TRANSACTION,
    caption: 'Confirm transaction',
  },
  {
    id: NFA_CODE_TYPE.CHANGE_PASSWORD,
    caption: 'Change password',
  },
  {
    id: NFA_CODE_TYPE.CONFIRM_P2P_TRANSACTION,
    caption: 'Confirm P2P transaction',
  },
  {
    id: NFA_CODE_TYPE.RECOVER_PASSWORD,
    caption: 'Recover password',
  },
  {
    id: NFA_CODE_TYPE.CONFIRM_GROUP_CHAT,
    caption: 'Confirm group chat',
  },
  {
    id: NFA_CODE_TYPE.CONFIRM_CODE,
    caption: 'Confirm code',
  },
  {
    id: NFA_CODE_TYPE.TELEGRAM_BOT_CLAIMS,
    caption: 'Telegram bot claims',
  },
  {
    id: NFA_CODE_TYPE.TELEGRAM_BOT_UNTIE,
    caption: 'Telegram bot untie',
  },
  {
    id: NFA_CODE_TYPE.TELEGRAM_BOT_TIE,
    caption: 'Telegram bot tie',
  },
  {
    id: NFA_CODE_TYPE.MFA_UPDATE,
    caption: 'MFA update',
  },
  {
    id: NFA_CODE_TYPE.SET_RECOVERY_KEY,
    caption: 'Set recovery key',
  },
  {
    id: NFA_CODE_TYPE.UNSET_RECOVERY_KEY,
    caption: 'Unset recovery key',
  },
  {
    id: NFA_CODE_TYPE.ACCESS_RECOVERY,
    caption: 'Access recovery',
  },
  {
    id: NFA_CODE_TYPE.ANTIPHISHING,
    caption: 'Antiphishing',
  },
  {
    id: NFA_CODE_TYPE.SESSION_SETTINGS,
    caption: 'Session settings',
  },
  {
    id: NFA_CODE_TYPE.ALLOWED_ADDRESS,
    caption: 'Allowed address',
  },
  {
    id: NFA_CODE_TYPE.NOTIFICATION,
    caption: 'Notification',
  },
  {
    id: NFA_CODE_TYPE.SET_EMAIL_ENCRYPT,
    caption: 'Set email encrypt',
  },
  {
    id: NFA_CODE_TYPE.UNSET_EMAIL_ENCRYPT,
    caption: 'Unset email encrypt',
  },
  {
    id: NFA_CODE_TYPE.EDIT_RECOVERY_KEY,
    caption: 'Edit recovery key',
  },
  {
    id: NFA_CODE_TYPE.ENABLE_MFA,
    caption: 'Enable MFA',
  },
].filter((e) => checkConstAvailability(e, { NFA_CODE_TYPE }));

const TWO_FACTOR_METHOD_TYPE = {
  NOTHING: 0,
  TELEGRAM: 1,
  TOTP: 2,
};

const TWO_FACTOR_METHOD_TYPE_LIST = [
  {
    id: TWO_FACTOR_METHOD_TYPE.NOTHING,
    caption: 'Nothing',
  },
  {
    id: TWO_FACTOR_METHOD_TYPE.TELEGRAM,
    caption: 'Telegram',
  },
  {
    id: TWO_FACTOR_METHOD_TYPE.TOTP,
    caption: 'TOTP',
  },
].filter((e) => checkConstAvailability(e, { TWO_FACTOR_METHOD_TYPE }));

export default {
  DOCUMENT_TYPE,
  DOCUMENT_TYPE_LIST,
  VERIFICATION_STATUS,
  VERIFICATION_STATUS_LIST,
  VERIFICATION_RESPONSE_TYPE,
  VERIFICATION_RESPONSE_TYPE_LIST,
  AUTO_VERIFICATION_STATUS,
  AUTO_VERIFICATION_STATUS_LIST,
  USER_ACTION_TYPE,
  USER_ACTION_TYPE_LIST,
  EMAIL_BODY_TYPE,
  EMAIL_BODY_TYPE_LIST,
  REFERRAL_INCOMES_TYPE,
  REFERRAL_INCOMES_TYPE_LIST,
  GENDER,
  GENDER_LIST,
  NFA_CODE_TYPE,
  NFA_CODE_TYPE_LIST,
  TWO_FACTOR_METHOD_TYPE,
  TWO_FACTOR_METHOD_TYPE_LIST,
};
