//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  props: {
    userId: { type: Number, default: 0 },
  },

  data() {
    return {
      language: '',
      loading: false,
    };
  },

  computed: {
    ...mapState('common', [
      'status',
    ]),
    message() {
      if (this.loading) return '';
      return this.$t('Users language: $language', { language: this.language });
    },
    isWrongLanguage() {
      const regex = {
        EN: /[a-zA-Z0-9]/,
        RU: /[а-яА-Я0-9]/,
      };
      if (regex[this.language] && this.$attrs.value?.[0] && !this.loading && !regex[this.language].test(this.$attrs.value[0])) return true;
      return false;
    },
    errorMessage() {
      if (this.isWrongLanguage) return this.$t('The comment is in language other than the users locale $language', { language: this.language });
      return '';
    },
  },

  mounted() {
    this.getUsersLanguage();
  },

  watch: {
    userId() {
      this.getUsersLanguage();
    },
  },

  methods: {
    ...mapActions('common', [
      'confirmAction',
    ]),
    ...mapActions('users', [
      'getUser',
    ]),

    async getUsersLanguage() {
      this.loading = true;
      const { language } = await this.getUser(this.userId);
      if (language !== undefined) this.language = this.status.languageList[language].toUpperCase();
      this.loading = false;
    },
  },
};
