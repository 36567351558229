import http from '~/http/index';
import constant from '~/const';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      return {
        ...filters,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit }, data) {
      const responses = await Promise.all(
        constant.statistics.REFERRAL_INCOME_TYPE_LIST.map((e) => {
          return http.post('v2/Referral/GetReferralStats', {
            ...getters.requestData,
            ...data,
            referralIncomeTypeList: [e.id],
          });
        }),
      );
      const result = {};
      constant.statistics.REFERRAL_INCOME_TYPE_LIST.forEach((e, i) => {
        result[e.id] = responses[i].data;
      });
      commit('SET_DATA', result);
    },
  },

  namespaced: true,
});
