export default [
  { title: 'System status', routeName: 'statistics' },

  { title: 'Gross profit', routeName: 'statistics-gross-profit' },

  { title: 'W / D turnover', routeName: 'statistics-wd-turnover' },

  { title: 'Trade turnover', routeName: 'statistics-trade-turnover' },

  { title: 'Ref program', routeName: 'statistics-ref-program' },

  { title: 'Staking', routeName: 'statistics-staking' },

  { title: 'Users', routeName: 'statistics-users' },
];
