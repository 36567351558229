import http from '~/http/index';

export default ({
  state: {
    data: {},
    chart: [],
    filters: {},
    chartFilters: {
      dateFrom: '',
      dateTo: '',
      period: '',
    },
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_CHART(state, data) {
      state.chart = data;
    },

    SET_CHART_FILTERS(state, data) { state.chartFilters = data; },

    SET_DATA(state, data) {
      state.data = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setChartFilters({ commit }, data) { commit('SET_CHART_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ commit }) {
      const response = await http.post('v2/User/GetUsersStats', {});
      commit('SET_DATA', response.data);
    },

    async loadChart({ state, commit }) {
      const responseArray = await Promise.all([
        http.post('v2/User/GetUsersChart', { ...state.chartFilters }),
        http.post('v2/User/GetUserVerificationsChart', { ...state.chartFilters }),
      ]);
      const chart = responseArray[0].data.items;
      const verificationsChart = responseArray[1].data.items;
      const result = chart.map((c) => {
        const verificationChart = verificationsChart.find((v) => v.t === c.t);
        if (!verificationChart) return c;
        return {
          ...c,
          ...verificationChart,
        };
      });
      commit('SET_CHART', result);
    },
  },

  namespaced: true,
});
