//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import menuList from '~/config/navigationFinance';

export default {
  data() {
    return {
      menuList,
    };
  },

  methods: {
    isCurrentRoute(name) {
      const currentRouteName = this.$route.name;
      return (name === currentRouteName);
    },
  },
};
