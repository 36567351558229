import http from '~/http/index';

export default ({
  state: {
    options: {},
  },

  mutations: {
    SET_OPTIONS(state, data) {
      state.options = data;
    },
  },

  actions: {
    async loadChatOptions({ commit }) {
      const response = await http.get('Options/GetChatOptions');
      const { data } = response;
      commit('SET_OPTIONS', data);
    },

    async updateChatOptions(__store, data) {
      await http.post('Options/UpdateChatOptions', data);
    },
  },

  namespaced: true,
});
