import http from '~/http/index';

export default ({
  state: {
    users: [],
    filters: {},
    userTagListFilter: [],
    sort: {
      sort: null,
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    sortList: [
      { id: null, caption: 'Default' },
      { id: 'regDate', caption: 'Registration date' },
      { id: 'lastLoginDate', caption: 'Last activity date' },
      { id: 'verificationRequestDate', caption: 'Verification request date' },
    ],
    filterLists: {
      userStatusList: [
        { id: 0, caption: '0 – New' },
        { id: 1, caption: '1 – Confirmed' },
        { id: 2, caption: '2 – Verificated' },
      ],
      nfaServiceTypeList: [
        { id: 0, caption: 'Email' },
        { id: 1, caption: 'Telegram' },
        { id: 2, caption: 'Google' },
      ],
    },
    userTagsList: [],
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const {
        filters,
        userTagListFilter,
        sort,
        page,
      } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        userTagList: userTagListFilter,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },

    getUserTagById: (state) => (id) => state.userTagsList.find((e) => e.id === id),

    getUserTagByTagName: (state) => (value) => state.userTagsList.find((e) => e.tagName === value),

    getUserTagsByIds: (__state, getters) => (data) => {
      if (!data) return [];

      return data
        .map((e) => getters.getUserTagById(e))
        .filter((e) => e);
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_USERS(state, data) {
      state.users = data;
    },

    EXTEND_USER(state, data) {
      const extendedData = state.users.map((e) => {
        if (e.id === data.id) {
          return {
            ...e,
            extendedUser: data,
          };
        }
        return e;
      });
      state.users = extendedData;
    },

    SET_USER_TAGS_LIST(state, data) {
      state.userTagsList = data;
    },

    SET_USER_TAG_LIST_FILTER(state, data) {
      state.userTagListFilter = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async getUsers({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/User/GetUsers', {
        ...getters.requestData,
        ...data,
      });
      const { items, total } = response.data;
      commit('SET_USERS', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async loadUser({ commit }, data) {
      const response = await http.post('v2/User/GetUser', data);
      commit('EXTEND_USER', response.data);
    },

    async getUser(__store, userId) {
      const response = await http.post('v2/User/GetUser', { userId });
      return response.data;
    },

    async getUsersByParams(__store, data) {
      const response = await http.post('v2/User/GetUsers', data);
      return response.data;
    },

    async getUsersBySearchString(__store, searchString) {
      try {
        const response = await http.post('v2/User/GetUsers', { searchString });
        return response.data;
      } catch (error) {
        return [];
      }
    },

    async getUserIdByUserName(__store, userName) {
      try {
        const response = await http.post('v2/User/GetUsers', {
          userName,
          limit: 1,
          offset: 0,
        });
        const user = response.data.items[0];
        if (user && user.userName === userName) {
          return user.id;
        }
        return false;
      } catch (__error) {
        return false;
      }
    },

    setUserTagListFilter({ commit }, data) {
      commit('SET_USER_TAG_LIST_FILTER', data);
    },

    async getUserTagsList({ commit }, data = { searchString: '' }) {
      const response = await http.post('v2/User/GetUserTags', data);
      const { items } = response.data;
      commit('SET_USER_TAGS_LIST', items);
    },

    async removeTagsFromUser(__state, data) {
      await http.put('v2/User/RemoveTagsFromUser', data);
    },

    async addUserTag(__state, data) {
      await http.post('v2/User/AddUserTag', data);
    },

    async editUserTag(__state, data) {
      await http.put('v2/User/EditUserTag', data);
    },

    async deleteUserTag(__state, tagId) {
      await http.put('v2/User/DeleteUserTag', { tagId });
    },

    async addTagsToUser(__state, data) {
      await http.put('v2/User/AddTagsToUser', data);
    },

    async usersLock(__store, data) {
      await http.put('v2/User/UsersLock', data);
    },

    async usersUnlock(__store, data) {
      await http.put('v2/User/UsersUnlock', data);
    },

    async usersBlock(__store, data) {
      await http.put('v2/User/UsersBlock', data);
    },

    async usersUnblock(__store, data) {
      await http.put('v2/User/UsersUnblock', data);
    },

    async expandUserData({ dispatch }, {
      data = [],
      getId = (e) => e.userId,
      key = 'user',
    }) {
      const userIdList = data.map((e) => getId(e));
      const requestParams = {
        userIdList,
        limit: data.length,
        getTotal: false,
      };
      const { items } = await dispatch('getUsersByParams', requestParams);
      return data.map((dataItem) => {
        if (Object.prototype.hasOwnProperty.call(dataItem, key)) {
          throw new Error(`Property "${key}" already exist in data item`);
        }
        const user = items.find((userItem) => userItem.id === getId(dataItem));
        return {
          ...dataItem,
          [key]: user,
        };
      });
    },

    async updateUserTransactionLimit(__store, data) {
      const response = await http.put('v2/Transaction/UpdateUserTransactionLimit', data);
      return response.data;
    },

    async deleteUserTransactionLimit(__store, data) {
      const response = await http.put('v2/Transaction/DeleteUserTransactionLimit', data);
      return response.data;
    },

    async createUser(__store, data) {
      const response = await http.post('v2/User/CreateUser', data);
      return response.data;
    },

    async addUserRole(__state, data) {
      await http.put('v2/User/AddUserRole', data);
    },

    async removeUserRole(__state, data) {
      await http.put('v2/User/RemoveUserRole', data);
    },
  },

  namespaced: true,
});
