import http from '~/http/index';
import constant from '~/const';

export default ({
  state: {
    data: [],
    additionalData: {},
    chart: [],
    additionalChart: {},
    filters: {},
    chartFilters: {
      currencyIdList: [],
    },
    sort: {
      sort: 'totalDeposit',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_ADDITIONAL_DATA(state, data) {
      state.additionalData = data;
    },

    SET_CHART(state, data) {
      state.chart = data;
    },

    SET_CHART_FILTERS(state, data) { state.chartFilters = data; },

    SET_ADDITIONAL_CHART(state, data) {
      state.additionalChart = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setChartFilters({ commit }, data) { commit('SET_CHART_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const {
        dateFrom,
        dateTo,
        cumulativeCurrencyId,
        userTagList,
      } = getters.requestData;
      const response = await http.post('v2/Finance/GetTransactionStats', {
        dateFrom,
        dateTo,
        userTagList,
        cumulativeCurrencyId,
        ignoreTransactionMethodTypeList: [
          constant.operations.TRANSACTION_METHOD_TYPE.CODE,
          constant.operations.TRANSACTION_METHOD_TYPE.INTERNAL,
        ],
        ...data,
      });
      const {
        items,
        total,
        cumulativeCurrencyId: _cumulativeCurrencyId,
        cumulativeCurrencyTitle,
      } = response.data;
      commit('SET_DATA', items);
      commit('SET_ADDITIONAL_DATA', { cumulativeCurrencyId: _cumulativeCurrencyId, cumulativeCurrencyTitle });
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async loadChart({ state, getters, commit }) {
      const {
        dateFrom,
        dateTo,
        period,
        cumulativeCurrencyId,
        userTagList,
      } = getters.requestData;
      const response = await http.post('v2/Finance/GetTransactionStatsChart', {
        ...state.chartFilters,
        dateFrom,
        dateTo,
        period,
        cumulativeCurrencyId,
        userTagList,
        ignoreTransactionMethodTypeList: [
          constant.operations.TRANSACTION_METHOD_TYPE.CODE,
          constant.operations.TRANSACTION_METHOD_TYPE.INTERNAL,
        ],
      });
      const { items, cumulativeCurrencyTitle } = response.data;
      commit('SET_CHART', items);
      commit('SET_ADDITIONAL_CHART', { cumulativeCurrencyTitle });
    },
  },

  namespaced: true,
});
