import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import utils from './utils';

// Global components
import DropdownMenu from './components/ui/dropdown-menu/Index.vue';
import DropdownList from './components/ui/dropdown-menu/List.vue';
import TheFilters from '~/components/filters/Index.vue';
import UiTextField from '~/components/ui/TextField.vue';
import UiTable from '~/components/ui/Table.vue';
import UiSelect from '~/components/ui/Select.vue';
import UiDate from '~/components/ui/Date.vue';
import UiDateRange from '~/components/ui/DateRange.vue';
import ContentPagination from '~/components/Pagination.vue';
import UiInputRange from '~/components/ui/InputRange.vue';
import UserComment from '~/components/UserComment.vue';

Vue.config.productionTip = false;

Vue.prototype.$utils = utils;

Vue.component('UiDropdownMenu', DropdownMenu);
Vue.component('DropdownList', DropdownList);
Vue.component('TheFilters', TheFilters);
Vue.component('UiTextField', UiTextField);
Vue.component('UiTable', UiTable);
Vue.component('ContentPagination', ContentPagination);
Vue.component('UiSelect', UiSelect);
Vue.component('UiDateRange', UiDateRange);
Vue.component('UiDate', UiDate);
Vue.component('UiContentPagination', ContentPagination);
Vue.component('UiInputRange', UiInputRange);
Vue.component('UiUserComment', UserComment);

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
