import constant from '../const/index';
import { sciToDec } from './scientific-to-decimal';
import { getCaption, getFullDate, isIdentificatorType } from './common';

export default {
  inOutAmount(amount, side) {
    const sciToDecAmount = sciToDec(amount);
    if (amount > 0) {
      return side === constant.operations.OPERATION_SIDE.OUT ? `-${sciToDecAmount}` : sciToDecAmount;
    }

    return sciToDecAmount;
  },

  getAdminOrAdbitrator(rootGetters, history) {
    const getAdminById = rootGetters['login/getAdminById'];
    const getArbitratorById = rootGetters['p2pArbitratorsList/getArbitratorById'];

    if (history.adminId !== null) {
      const admin = getAdminById(history.adminId);
      return admin ? admin.email : '';
    }
    if (history.arbitratorId !== null) {
      const arbitrator = getArbitratorById(history.arbitratorId);
      return arbitrator ? arbitrator.email : '';
    }

    return ' ';
  },

  getFullDate,
  getCaption,
  isIdentificatorType,
};
