//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      isShow: false,
    };
  },

  computed: {
    ...mapState('common', ['successNotification']),
  },

  watch: {
    successNotification(value) {
      if (value) {
        this.isShow = true;
      }
    },
  },

  methods: {
    ...mapActions('common', ['setSuccessNotification']),

    show(value) {
      if (!value) {
        this.setSuccessNotification('');
      }
    },
  },
};
