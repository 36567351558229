import checkConstAvailability from '~/utils/check-const-availability';

const CLIENT_CLAIM_TYPE = {
  DEPOSIT: 2,
  WITHDRAWAL: 4,
  TRADE: 8,
  DATA: 16,
  P2P_TRADE: 32,
  CRYPTOMAT: 64,
};

const CLIENT_CLAIM_TYPE_LIST = [
  {
    id: CLIENT_CLAIM_TYPE.DEPOSIT,
    caption: 'Deposit',
  },
  {
    id: CLIENT_CLAIM_TYPE.WITHDRAWAL,
    caption: 'Withdrawal',
  },
  {
    id: CLIENT_CLAIM_TYPE.TRADE,
    caption: 'Trade',
  },
  {
    id: CLIENT_CLAIM_TYPE.DATA,
    caption: 'Data',
  },
  {
    id: CLIENT_CLAIM_TYPE.P2P_TRADE,
    caption: 'P2P trade',
  },
  {
    id: CLIENT_CLAIM_TYPE.CRYPTOMAT,
    caption: 'Cryptomat',
  },
].filter((e) => checkConstAvailability(e, { CLIENT_CLAIM_TYPE }));

const SOURCE_TYPE = {
  UNDEFINED: 0,
  SITE: 1,
  MOBILE: 2,
  PUBLIC: 3,
  TELEGRAM: 4,
  CRYPTOMAT: 5,
  PAYMENT: 6,
};

const SOURCE_TYPE_LIST = [
  // {
  //   id: SOURCE_TYPE.UNDEFINED,
  //   caption: 'Undefined',
  // },
  {
    id: SOURCE_TYPE.SITE,
    caption: 'Site',
  },
  {
    id: SOURCE_TYPE.MOBILE,
    caption: 'Mobile',
  },
  {
    id: SOURCE_TYPE.PUBLIC,
    caption: 'Public',
  },
  {
    id: SOURCE_TYPE.TELEGRAM,
    caption: 'Telegram',
  },
  {
    id: SOURCE_TYPE.CRYPTOMAT,
    caption: 'Cryptomat',
  },
  {
    id: SOURCE_TYPE.PAYMENT,
    caption: 'Payment',
  },
].filter((e) => checkConstAvailability(e, { SOURCE_TYPE }));

const ACTION_TYPE = {
  OTHER: 0,
  ENABLE: 1,
  UPDATE: 2,
  DISABLE: 3,
  TRADING: 4,
  TRANSACTION: 5,
  P2P: 6,
};

const ACTION_TYPE_LIST = [
  {
    id: ACTION_TYPE.OTHER,
    caption: 'Other',
  },
  {
    id: ACTION_TYPE.ENABLE,
    caption: 'Enable',
  },
  {
    id: ACTION_TYPE.UPDATE,
    caption: 'Update',
  },
  {
    id: ACTION_TYPE.DISABLE,
    caption: 'Disable',
  },
  {
    id: ACTION_TYPE.TRADING,
    caption: 'Trading',
  },
  {
    id: ACTION_TYPE.TRANSACTION,
    caption: 'Transaction',
  },
  {
    id: ACTION_TYPE.P2P,
    caption: 'P2P',
  },
].filter((e) => checkConstAvailability(e, { ACTION_TYPE }));

const HTTP_METHOD_TYPE = {
  GET: 0,
  POST: 1,
  PUT: 2,
  PATCH: 3,
  DELETE: 4,
};

const HTTP_METHOD_TYPE_LIST = [
  {
    id: HTTP_METHOD_TYPE.GET,
    caption: 'GET',
  },
  {
    id: HTTP_METHOD_TYPE.POST,
    caption: 'POST',
  },
  {
    id: HTTP_METHOD_TYPE.PUT,
    caption: 'PUT',
  },
  {
    id: HTTP_METHOD_TYPE.PATCH,
    caption: 'PATCH',
  },
  {
    id: HTTP_METHOD_TYPE.DELETE,
    caption: 'DELETE',
  },
];

export default {
  CLIENT_CLAIM_TYPE,
  CLIENT_CLAIM_TYPE_LIST,
  SOURCE_TYPE,
  SOURCE_TYPE_LIST,
  ACTION_TYPE,
  ACTION_TYPE_LIST,
  HTTP_METHOD_TYPE,
  HTTP_METHOD_TYPE_LIST,
};
