import http from '~/http/index';

export default ({
  state: {
    p2pCurrencies: [],
    p2pPaymentMethods: [],
  },

  getters: {
    getP2pCurrencyById: (state) => (id) => state.p2pCurrencies.find((e) => e.id === id),
    getP2pCurrencyByTitle: (state) => (title) => state.p2pCurrencies.find((e) => e.title === title),
  },

  mutations: {
    SET_P2P_CURRENCIES(state, data) {
      state.p2pCurrencies = data;
    },
    SET_P2P_PAYMENT_METHOD(state, data) {
      state.p2pPaymentMethods = data;
    },
  },

  actions: {
    async loadP2pCurrencies({ commit }) {
      const response = await http.get('p2p/Admin/Currency');
      const { data } = response.data;
      await commit('SET_P2P_CURRENCIES', data);
    },
    async loadP2pPaymentMethods({ commit }) {
      const { data } = await http.get('Transaction/GetPaymentMethods', {
        params: {
          Limit: 1000,
        },
      });
      await commit('SET_P2P_PAYMENT_METHOD', data);
    },
  },

  namespaced: true,
});
