export default [
  {
    path: '',
    name: 'user-id',
    component: () => import(/* webpackChunkName: "user-id" */ '~/views/UserId/UserId.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

  {
    path: 'security',
    name: 'user-security',
    component: () => import(/* webpackChunkName: "user-security" */ '~/views/UserSecurity/UserSecurity.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

  {
    path: 'notifications',
    name: 'user-notifications',
    component: () => import(/* webpackChunkName: "user-notifications" */ '~/views/UserNotifications/UserNotifications.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

  {
    path: 'api',
    name: 'user-api',
    component: () => import(/* webpackChunkName: "user-api" */ '~/views/UserApi/UserApi.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },


  {
    path: 'trading',
    name: 'user-trading',
    component: () => import(/* webpackChunkName: "user-trading" */ '~/views/UserTrading/Trading.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

  {
    path: 'balance',
    name: 'user-balance',
    component: () => import(/* webpackChunkName: "user-balance" */ '~/views/UserBalance/UserBalance.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

  {
    path: 'referrals',
    name: 'user-referrals',
    component: () => import(/* webpackChunkName: "user-referrals" */ '~/views/UserReferral/UserReferral.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

  {
    path: 'chats',
    name: 'user-chats',
    component: () => import(/* webpackChunkName: "user-chats" */ '~/views/UserChats/Chats.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

  {
    path: 'staking',
    name: 'user-staking',
    component: () => import(/* webpackChunkName: "user-staking" */ '~/views/UserStaking/UserStaking.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderUser' },
      rootRouteName: 'users',
    },
  },

];
