import http from '~/http/index';

const convertFees = (items) => {
  const itemsPrepared = [];
  items.forEach((item) => {
    if (item.deposit) {
      itemsPrepared.push({
        deposit: true,
        currency: item.currency,
        method: item.method,
        provider: item.provider,
        serviceMerchantId: item.serviceMerchantId,
        ...item.deposit,
      });
    }
    if (item.withdrawal) {
      itemsPrepared.push({
        deposit: false,
        currency: item.currency,
        method: item.method,
        provider: item.provider,
        serviceMerchantId: item.serviceMerchantId,
        ...item.withdrawal,
      });
    }
  });
  return itemsPrepared;
};

export default ({
  state: {
    data: [],
    providers: [],
    currencies: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: 10000,
        getTotal: true,
      };
    },

    currencyList(state) {
      return state.currencies.map((e) => e.name);
    },

    providerList(state) {
      return state.providers.map((e) => e.name);
    },

    methodList(state) {
      const methods = new Set();
      state.providers.forEach((provider) => {
        provider.currencies.forEach((currency) => {
          currency.deposits.forEach((method) => {
            methods.add(method.method);
          });
          currency.withdrawals.forEach((method) => {
            methods.add(method.method);
          });
        });
      });
      return Array.from(methods);
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_PROVIDERS(state, data) {
      state.providers = data;
    },

    SET_CURRENCIES(state, data) {
      state.currencies = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.get('v2/Merchant/fee', {
        params: {
          ...getters.requestData,
          ...data,
          ignoreIndividual: true,
        },
      });
      const { items, total } = response.data.result;
      const itemsPrepared = convertFees(items);
      commit('SET_DATA', itemsPrepared);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async loadIndividualData({ getters, commit }, { serviceMerchantId, ...data }) {
      const response = await http.get('v2/Merchant/fee', {
        params: {
          ...getters.requestData,
          ...data,
          ignoreIndividual: true,
        },
      });
      const { items } = response.data.result;

      const responseInd = await http.get('v2/Merchant/fee', {
        params: {
          ...getters.requestData,
          ...data,
          serviceMerchantId,
        },
      });
      const { items: itemsInd } = responseInd.data.result;
      const itemsIndPrepared = convertFees(itemsInd).filter((e) => +e.serviceMerchantId === +serviceMerchantId);
      const itemsPrepared = convertFees(items).filter((e) => !itemsIndPrepared.find((i) => e.deposit === i.deposit && e.currency === i.currency && e.method === i.method && e.provider === i.provider));
      const itemsComputed = itemsIndPrepared.concat(itemsPrepared);

      commit('SET_DATA', itemsComputed);
    },

    async editFee(__store, { serviceMerchantId, ...data }) {
      const path = `v2/Merchant/fee/${serviceMerchantId ?? ''}`;
      const response = await http.post(path, data);
      return response.data;
    },

    async loadProviders({ commit }) {
      const response = await http.get('v2/Merchant/provider');
      const { items } = response.data.result;
      commit('SET_PROVIDERS', items);
    },

    async loadCurrencies({ commit }) {
      const response = await http.get('v2/Merchant/currency');
      const { items } = response.data.result;
      commit('SET_CURRENCIES', items);
    },
  },

  namespaced: true,
});
