/* eslint max-len: "off" */
import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '~/views/Home.vue';

import userRoutes from '~/router/user';
import paymentsAndCurrenciesRoutes from '~/router/paymentsAndCurrencies';
import settingsRoutes from '~/router/settings';
import financeRoutes from '~/router/finance';
import p2pRoutes from '~/router/p2p';
import tradersRoutes from '~/router/traders';
import statisticsRoutes from '~/router/statistics';
import transactionsRoutes from '~/router/transactions';
import merchantsRoutes from '~/router/merchants';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      header: { type: 'text', value: 'Admin panel' },
    },
    children: [
      // {
      //   path: '/boilerplate',
      //   name: 'boilerplate',
      //   component: () => import(/* webpackChunkName: "frontConfig" */ '~/views/Boilerplate/Boilerplate.vue'),
      //   meta: {
      //     header: { type: 'text', value: 'Boilerplate' },
      //   },
      // },


      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '~/views/Dashboard/Dashboard.vue'),
        meta: {
          header: { type: 'text', value: 'Dashboard' },
        },
      },

      {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '~/views/Users/Users.vue'),
        meta: {
          header: { type: 'text', value: 'Users' },
        },
      },

      {
        path: '/user/:id',
        component: () => import(/* webpackChunkName: "user" */ '~/views/User/User.vue'),
        children: userRoutes,
      },

      {
        path: '/chats',
        name: 'chats',
        component: () => import(/* webpackChunkName: "chats" */ '~/views/Chats/Chats.vue'),
        meta: {
          header: { type: 'text', value: 'Chats' },
        },
      },

      {
        path: '/transactions',
        // name: 'transactions',
        component: () => import(/* webpackChunkName: "transactions" */ '~/views/Transactions/Transactions.vue'),
        children: transactionsRoutes,
        // meta: {
        //   header: { type: 'text', value: 'Transactions' },
        // },
      },


      {
        path: '/trading',
        name: 'trading',
        component: () => import(/* webpackChunkName: "trading" */ '~/views/Trading/Trading.vue'),
        meta: {
          header: { type: 'text', value: 'Trading' },
        },
      },

      {
        path: '/payment-systems',
        component: () => import(/* webpackChunkName: "paymentSystemsAndCurrencies" */ '~/views/PaymentsAndCurrencies/PaymentAndCurrencies.vue'),
        children: paymentsAndCurrenciesRoutes,
      },

      {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '~/views/Account/Account.vue'),
        meta: {
          header: { type: 'text', value: 'Account' },
        },
      },

      {
        path: '/referral-program',
        name: 'referralProgram',
        component: () => import(/* webpackChunkName: "referralProgram" */ '~/views/ReferralProgram/ReferralProgram.vue'),
        meta: {
          header: { type: 'text', value: 'Referral program' },
        },
      },

      {
        path: '/admin-management',
        name: 'adminManagement',
        component: () => import(/* webpackChunkName: "adminManagement" */ '~/views/AdminManagement/AdminManagement.vue'),
        meta: {
          header: { type: 'text', value: 'Admin management' },
        },
      },

      {
        path: '/settings',
        component: () => import(/* webpackChunkName: "settings" */ '~/views/Settings/Settings.vue'),
        children: settingsRoutes,
      },

      {
        path: '/markets',
        name: 'markets',
        component: () => import(/* webpackChunkName: "markets" */ '~/views/Markets/Markets.vue'),
        meta: {
          header: { type: 'text', value: 'Markets' },
        },
      },


      {
        path: '/finance',
        component: () => import(/* webpackChunkName: "finance" */ '~/views/Finance/Finance.vue'),
        children: financeRoutes,
      },



      {
        path: '/statistics',
        component: () => import(/* webpackChunkName: "statistics" */ '~/views/Statistics/Statistics.vue'),
        children: statisticsRoutes,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '~/views/Login.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
