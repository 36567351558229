import checkConstAvailability from '~/utils/check-const-availability';

const PARAMINING_LOG_TYPE = {
  PERC_CHANGE: 0,
  INCOME: 1,
  SETTINGS_ADD: 2,
  SETTINGS_REMOVE: 3,
};

const PARAMINING_LOG_TYPE_LIST = [
  {
    id: PARAMINING_LOG_TYPE.PERC_CHANGE,
    caption: 'Percent change',
  },
  {
    id: PARAMINING_LOG_TYPE.INCOME,
    caption: 'Income',
  },
  {
    id: PARAMINING_LOG_TYPE.SETTINGS_ADD,
    caption: 'Settings add',
  },
  {
    id: PARAMINING_LOG_TYPE.SETTINGS_REMOVE,
    caption: 'Settings remove',
  },
].filter((e) => checkConstAvailability(e, { PARAMINING_LOG_TYPE }));

export default {
  PARAMINING_LOG_TYPE,
  PARAMINING_LOG_TYPE_LIST,
};
