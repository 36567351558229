import http from '~/http/index';
import constant from '~/const';

export default ({
  state: {
    transactionsStatistics: [],
    filters: {},
    sort: {
      sort: 'totalDeposit',
      sortDesc: false,
    },
    filterLists: {
      priceEquivalentList: [
        { id: 0, caption: '' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters, sort } = state;
      return {
        ...filters,
        ...sort,
      };
    },

    currencyTypeList(_state, _getters, rootState) {
      const { currencies } = rootState.currencies;
      return [
        {
          id: currencies
            .filter((e) => e.currencyType === constant.currencies.CURRENCY_TYPE.FIAT)
            .map((e) => e.id),
          caption: 'Fiat',
        },
        {
          id: currencies
            .filter((e) => e.currencyType === constant.currencies.CURRENCY_TYPE.CRYPTO)
            .map((e) => e.id),
          caption: 'Crypto',
        },
      ];
    },

    currencyList(_state, _getters, rootState) {
      const { currencies } = rootState.currencies;
      return currencies;
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },

    SET_TRANSACTIONS_STATISTICS(state, data) {
      state.transactionsStatistics = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },

    async getTransactionStats({ getters, commit }, data) {
      const response = await http.post('Transaction/GetTransactionStats', {
        ...getters.requestData,
        ...data,
      });
      const { items } = response.data;
      commit('SET_TRANSACTIONS_STATISTICS', items);
    },
  },

  namespaced: true,
});
