import http from '~/http/index';
// import { sciToDec } from '~/utils/scientific-to-decimal';

export default ({
  state: {
    currencies: [],
    markets: [],
  },

  getters: {
    getCurrencyById: (state) => (id) => state.currencies.find((e) => e.id === id),
    getCurrencyByTitle: (state) => (title) => state.currencies.find((e) => e.title === title),

    toCurrencyFormat: (state) => (value, currencyLabel) => {
      const currency = state.currencies.find((e) => {
        return (e.id === currencyLabel) || (e.title === currencyLabel);
      });
      const decimalsCount = currency ? currency.decimals : 8;
      return new Intl.NumberFormat(
        'en-US',
        { maximumFractionDigits: decimalsCount },
      ).format(value);
    },

    getUrlTx: (_state, _getters, _rootState, rootGetters) => (txId, currencyLabel) => {
      const currency = rootGetters['paymentsAndCurrenciesPayments/cryptoCurrencies'].find((e) => {
        return (e.id === currencyLabel) || (e.title === currencyLabel);
      });
      if (!currency) return null;
      const { urlTx } = currency;
      if (!urlTx) return null;
      return urlTx.replace('{txid}', txId);
    },

    getTokenUrlTx: (_state, _getters, _rootState, rootGetters) => (txId, currencyLabel) => {
      const currency = rootGetters['paymentsAndCurrenciesPayments/cryptoTokens'].find((e) => {
        return (e.id === currencyLabel) || (e.title === currencyLabel);
      });
      if (!currency) return null;
      const { urlTx } = currency;
      if (!urlTx) return null;
      return urlTx.replace('{txid}', txId);
    },

    getUrlAddress: (_state, _getters, _rootState, rootGetters) => (address, currencyLabel) => {
      const currency = rootGetters['paymentsAndCurrenciesPayments/cryptoCurrencies'].find((e) => {
        return (e.id === currencyLabel) || (e.title === currencyLabel);
      });
      if (!currency) return null;
      const { urlAddress } = currency;
      if (!urlAddress) return null;
      return urlAddress.replace('{address}', address);
    },

    getTokenUrlAddress: (_state, _getters, _rootState, rootGetters) => (address, currencyLabel) => {
      const currency = rootGetters['paymentsAndCurrenciesPayments/cryptoTokens'].find((e) => {
        return (e.id === currencyLabel) || (e.title === currencyLabel);
      });
      if (!currency) return null;
      const { urlAddress } = currency;
      if (!urlAddress) return null;
      return urlAddress.replace('{address}', address);
    },
  },

  mutations: {
    SET_CURRENCIES(state, data) {
      state.currencies = data.map((item) => {
        return {
          ...item, name: `${item.name} (${item.title})`,
        };
      });
    },

    SET_MARKETS(state, data) {
      state.markets = data;
    },
  },

  actions: {
    async getCurrencies({ commit }) {
      const { data } = await http.get('Market/GetCurrencies');
      await commit('SET_CURRENCIES', data);
    },

    async getMarkets({ commit }) {
      const { data } = await http.get('Market/GetMarkets');
      await commit('SET_MARKETS', data);
    },
  },

  namespaced: true,
});
