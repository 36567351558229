import http from '~/http/index';

export default ({
  state: {
    privateChats: [],
    currentChat: [],
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_PRIVATE_CHATS(state, data) {
      state.privateChats = data;
    },

    SET_CURRENT_CHAT(state, data) {
      state.currentChat = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async getPrivateChats({ getters, commit, rootGetters }, params) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Communication/GetPrivateChats', {
        ...getters.requestData,
        ...params,
      });

      const { items, total } = response.data;
      commit('SET_PRIVATE_CHATS', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async deleteChat(__store, conversationId) {
      await http.put('v2/Communication/DeletePrivateChat', { conversationId });
    },

    async deleteChatMessage(__store, messageId) {
      await http.put('v2/Communication/DeletePrivateChatMessage', { messageId });
    },

    async editChatMessage(__store, data) {
      await http.put('v2/Communication/EditPrivateChatMessage', data);
    },

    async loadCurrentChat({ commit }, conversationId) {
      const response = await http.post('v2/Communication/GetPrivateChatMessages', {
        conversationId,
        limit: 1000,
        offset: 0,
        getTotal: false,
        sort: 'dateCreated',
        sortDesc: true,
      });
      const { items } = response.data;
      commit('SET_CURRENT_CHAT', items);
    },
  },

  namespaced: true,
});
