import http from '~/http/index';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'CreatedAt',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
    chatMessages: [],
    feedback: [],
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        page: page - 1,
        limit: itemsPerPage,
        getTotal: true,
      };
    },

    sortedByDateChatMessages(state) {
      return state.chatMessages.sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated));
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_CHAT_MESSAGES(state, data) {
      state.chatMessages = data;
    },

    SET_FEEDBACK(state, data) {
      state.feedback = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit }, params) {
      const response = await http.get('p2p/Admin/Trade', {
        params: {
          ...getters.requestData,
          ...params,
        },
      });
      const { data, pages } = response.data;
      commit('SET_DATA', data);
      if (pages) {
        commit('SET_PAGES', pages);
      }
    },

    async getChatMessages({ commit }, params) {
      const response = await http.get(`p2p/Admin/Trade/${params.id}/chat/getMessages`, {
        params,
      });
      const { data } = response.data;
      commit('SET_CHAT_MESSAGES', data);
    },

    async getFeedback({ commit }, params) {
      const response = await http.get('p2p/Admin/feedback', {
        params,
      });
      const { data } = response.data;
      commit('SET_FEEDBACK', data);
    },

    async deleteFeedback(__store, feedbackId) {
      const response = await http.delete(`p2p/Admin/feedback/${feedbackId}`);
      return response.data;
    },

    async editFeedback(__store, { feedbackId, comment, influence }) {
      const response = await http.put(`p2p/Admin/feedback/${feedbackId}`, {
        comment,
        influence,
      });
      return response.data;
    },
  },

  namespaced: true,
});
