export default [
  {
    path: '',
    name: 'payment-systems',
    component: () => import(/* webpackChunkName: "paymentSystemsAndCurrencies-systems" */ '~/views/PaymentsAndCurrenciesSystem/PaymentsAndCurrenciesSystem.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderPaymentsAndCurrencies' },
    },
  },

  {
    path: 'currencies-and-tokens',
    name: 'payment-systems-and-currencies',
    component: () => import(/* webpackChunkName: "paymentSystemsAndCurrencies-currenciesAndTokens" */ '~/views/PaymentsAndCurrenciesCurrenciesAndTokens/PaymentsAndCurrenciesCurrenciesAndTokens.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderPaymentsAndCurrencies' },
      rootRouteName: 'payment-systems',
    },
  },

  {
    path: 'network',
    name: 'payment-systems-networks',
    component: () => import(/* webpackChunkName: "paymentSystemsAndCurrencies-networks" */ '~/views/PaymentsAndCurrenciesNetworks/PaymentsAndCurrenciesNetworks.vue'),
    meta: {
      header: { type: 'slot', name: 'HeaderPaymentsAndCurrencies' },
      rootRouteName: 'payment-systems',
    },
  },
];
