import http from '~/http/index';
import constant from '~/const';

export default ({
  state: {
    data: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters, sort } = state;
      return {
        ...filters,
        ...sort,
      };
    },
    // requestData(state, _getters, _rootState, rootGetters) {
    //   const { filters, sort, page } = state;
    //   const itemsPerPage = rootGetters['common/itemsPerPage'];
    //   return {
    //     ...filters,
    //     ...sort,
    //     offset: (page - 1) * itemsPerPage,
    //     limit: itemsPerPage,
    //     getTotal: true,
    //   };
    // },

    currencyTypeList(_state, _getters, rootState) {
      const { currencies } = rootState.currencies;
      return [
        {
          id: currencies
            .filter((e) => e.currencyType === constant.currencies.CURRENCY_TYPE.FIAT)
            .map((e) => e.id),
          caption: 'Fiat',
        },
        {
          id: currencies
            .filter((e) => e.currencyType === constant.currencies.CURRENCY_TYPE.CRYPTO)
            .map((e) => e.id),
          caption: 'Crypto',
        },
      ];
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      const reduceWallets = (array) => array.reduce((from, wallet) => from + wallet.balance, 0);
      state.data = data.map((item) => {
        return {
          ...item,
          balanceOnWallets: reduceWallets(item.wallets),
          diffBalance: reduceWallets(item.wallets.filter(({ main }) => main)) - item.balanceUser,
        };
      });
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Finance/GetFinanceStatus', {
        ...getters.requestData,
        ...data,
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },
  },

  namespaced: true,
});
