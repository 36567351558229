import checkConstAvailability from '~/utils/check-const-availability';

const PERIOD = {
  DAY: 0,
  WEEK: 1,
  MONTH: 2,
  YEAR: 3,
};

const PERIOD_LIST = [
  {
    id: PERIOD.DAY,
    caption: 'Day',
  },
  {
    id: PERIOD.WEEK,
    caption: 'Week',
  },
  {
    id: PERIOD.MONTH,
    caption: 'Month',
  },
  {
    id: PERIOD.YEAR,
    caption: 'Year',
  },
];

const REFERRAL_INCOME_TYPE = {
  TRADE: 0,
  P2P: 1,
  PARAMINING: 2,
};

const REFERRAL_INCOME_TYPE_LIST = [
  {
    id: REFERRAL_INCOME_TYPE.TRADE,
    caption: 'Trade',
  },
  {
    id: REFERRAL_INCOME_TYPE.P2P,
    caption: 'P2P',
  },
  {
    id: REFERRAL_INCOME_TYPE.PARAMINING,
    caption: 'Staking',
  },
].filter((e) => checkConstAvailability(e, { REFERRAL_INCOME_TYPE }));

export default {
  PERIOD,
  PERIOD_LIST,
  REFERRAL_INCOME_TYPE,
  REFERRAL_INCOME_TYPE_LIST,
};
