import http from '~/http/index';
import constant from '~/const/index';

export default ({
  state: {
    tradingOrders: [],
    filters: {},
    sort: {
      sort: 'dateCreated',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    sortList: [
      { id: '', caption: '' },
    ],
    filterLists: {
      List: [
        { id: 0, caption: '' },
      ],
      sideList: [
        { id: constant.orders.ORDER_SIDE.BID, caption: 'Buy' },
        { id: constant.orders.ORDER_SIDE.ASK, caption: 'Sell' },
      ],
    },
    innerTrades: [],
  },

  getters: {
    requestData(state, _getters, rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const { id } = rootState.user.user;
      return {
        userIdList: [id],
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_TRADING_ORDERS(state, data) {
      state.tradingOrders = data;
    },

    ADD_INNER_TRADE(state, data) {
      const index = state.innerTrades.findIndex((e) => e.orderId === data.orderId);
      if (index === -1) {
        state.innerTrades.push(data);
      } else {
        state.innerTrades[index] = data;
      }
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async getTradingOrders({
      getters,
      commit,
      rootGetters,
    }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Trading/GetOrders', {
        ...getters.requestData,
        ...data,
      });
      const { items, total } = response.data;
      commit('SET_TRADING_ORDERS', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async loadInnerTrades({ commit }, data) {
      const response = await http.post('v2/Trading/GetTrades', {
        ...data,
        limit: 1000,
        offset: 0,
        getTotal: false,
      });
      const { items } = response.data;

      const result = {
        orderId: data.orderIdList[0],
        tradeList: items,
      };
      commit('ADD_INNER_TRADE', result);
    },

    async cancelOrderList(__store, data) {
      return http.put('Trading/CancelOrder', data);
    },
  },

  namespaced: true,
});
