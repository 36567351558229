import http from '~/http/index';

export default ({
  state: {
    data: [],
    emailMessageTemplateHistory: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    // requestData(state, _getters, _rootState, rootGetters) {
    //   const { filters, sort, page } = state;
    //   const itemsPerPage = rootGetters['common/itemsPerPage'];
    //   return {
    //     ...filters,
    //     ...sort,
    //     offset: (page - 1) * itemsPerPage,
    //     limit: itemsPerPage,
    //     getTotal: true,
    //   };
    // },
    requestData(state) {
      const { filters } = state;
      return {
        ...filters,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_EMAIL_MESSAGE_TEMPLATE_HISTORY(state, data) {
      state.emailMessageTemplateHistory = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Templates/GetEmailMessageTemplates', {
        ...getters.requestData,
        ...data,
      });
      const { items, total } = response.data;
      commit('SET_DATA', items);
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async updateEmailMessageTemplate(__store, data) {
      const response = await http.post('v2/Templates/UpdateEmailMessageTemplate', data);
      return response.data;
    },

    async addEmailMessageTemplate(__store, data) {
      const response = await http.post('v2/Templates/AddEmailMessageTemplate', data);
      return response.data;
    },

    async loadEmailMessageTemplateHistory({ commit }, data) {
      const response = await http.post('v2/Templates/GetEmailMessageTemplateHistory', data);
      const { items } = response.data;
      commit('SET_EMAIL_MESSAGE_TEMPLATE_HISTORY', items);
    },

    async sendEmailToUsers(__store, data) {
      const response = await http.post('v2/Communication/SendNotificationsToUsers', data);
      return response.data;
    },

    async getEmailOptions() {
      const response = await http.get('Options/GetEmailOptions');
      return response.data;
    },

    async updateEmailOptions(__store, data) {
      const response = await http.post('Options/UpdateEmailOptions', data);
      return response.data;
    },
  },

  namespaced: true,
});
