import http from '~/http/index';

export default ({
  state: {
    userDocuments: [],
    verificationInfo: {},
    userFile: '',
  },

  getters: {
    filteredUserDocuments(state) {
      const { userDocuments } = state;
      const documentKeysList = ['firstName', 'lastName', 'middleName', 'birthday', 'country', 'city', 'address', 'citizenship', 'documentExpireDate', 'documentIssuedBy', 'documentIssuedDate', 'documentNumber'];

      return userDocuments.filter((userDocument) => {
        const isHasValueIndex = documentKeysList.findIndex((keyName) => {
          return !(userDocument[keyName] === null);
        });
        if (isHasValueIndex !== -1) return userDocument;

        return null;
      });
    },
  },

  mutations: {
    SET_USER_DOCUMENTS(state, data) {
      state.userDocuments = data;
    },
    SET_VERIFICATION_INFO(state, data) {
      state.verificationInfo = data;
    },
    SET_USER_FILE(state, value) {
      state.userFile = value;
    },
  },

  actions: {
    async loadUserDocuments({ commit, rootState }) {
      const { id } = rootState.user.user;
      const responses = await Promise.all([
        http.post('v2/User/GetUserDocuments', {
          userIdList: [id],
          limit: 1000,
          offset: 0,
          getTotal: false,
        }),
        http.get('User/GetVerificationInfo', {
          params: { userId: id },
        }),
      ]);
      commit('SET_USER_DOCUMENTS', responses[0].data.items);
      commit('SET_VERIFICATION_INFO', responses[1].data);
    },

    async checkUserDocument(__store, data) {
      const response = await http.put('v2/User/CheckUserDocument', data);
      return response.data;
    },

    async getUserFile({ commit }, UserFileId) {
      const { data } = await http.get('User/GetUserFile', {
        params: { UserFileId },
      });
      commit('SET_USER_FILE', data);
    },

    async removeUser(__store, userId) {
      const response = await http.put('v2/User/RemoveUser', { userId });
      return response.data;
    },
  },

  namespaced: true,
});
