import http from '~/http/index';
import { updateData } from '~/utils/common';



export default ({
  state: {
    data: [],
    initialData: [],
    defaultData: [],
    filters: {},
    sort: {
      sort: 'defaultSort',
      sortDesc: false,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      return { ...filters };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_INITIAL_DATA(state, data) {
      state.initialData = data;
    },

    SET_DEFAULT_DATA(state, data) {
      state.defaultData = data;
    },

    SET_LOCALISATION_DATA(state, localisationData) {
      state.data = updateData(state.data, localisationData);
      state.defaultData = updateData(state.defaultData, localisationData);
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit }, data) {
      const response = await http.post('v2/Templates/GetNotificationTemplates', {
        ...getters.requestData,
        ...data,
      });
      const { items } = response.data;
      commit('SET_DATA', items);
      commit('SET_INITIAL_DATA', items);
    },

    async loadInitialData({ getters, commit }, data) {
      const response = await http.post('v2/Templates/GetNotificationTemplates', {
        ...getters.requestData,
        ...data,
      });
      const { items } = response.data;
      commit('SET_INITIAL_DATA', items);
    },

    async loadDefaultData({ commit }) {
      const response = await http.post('v2/Templates/GetDefaultNotificationTemplates');
      const { items } = response.data;
      commit('SET_DEFAULT_DATA', items);
    },

    async updateNotificationTemplate(__store, data) {
      const response = await http.post('v2/Templates/AddNotificationTemplate', data);
      return response.data;
    },

    async loadP2PLocalisation({ getters, commit }) {
      const { language } = getters.requestData;
      const response = await http.get(`p2p/Admin/Localisation/All?language=${language}`);
      const { data } = response.data;
      commit('SET_LOCALISATION_DATA', data);
    },

    async updateP2PNotification(__store, data) {
      const response = await http.put(`p2p/Admin/Localisation/${data.id}`, data);
      return response.data;
    },
  },

  namespaced: true,
});
