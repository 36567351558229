import http from '~/http/index';

export default ({
  state: {
    data: {},
    items: [],
    filters: {},
    chart: [],
    currentCurrencyId: 0,
    additionalChart: 0,
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      return {
        ...filters,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = {
        cumulativeCurrencyId: data.cumulativeCurrencyId,
        cumulativeCurrencyTitle: data.cumulativeCurrencyTitle,
        cumulativeBalance: data.cumulativeBalance,
        cumulativeRewards: data.cumulativeRewards,
      };
    },

    SET_ITEMS(state, items) {
      state.items = items.map((item) => {
        return {
          ...item,
          id: item.currencyId,
        };
      });
    },
    SET_CHART(state, items) {
      state.chart = items;
    },
    SET_CURRENT_CURRENCY_ID(state, data) {
      state.currentCurrencyId = data;
    },
    SET_ADDITIONAL_CHART(state, data) {
      state.additionalChart = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit }, request) {
      const response = await http.post('v2/Finance/GetStakingStats ', {
        ...getters.requestData,
        ...request,
      });
      const { data } = response;
      if (data.items.length > 0) {
        commit('SET_CURRENT_CURRENCY_ID', data.items[0].currencyId);
      }
      commit('SET_ADDITIONAL_CHART', data.cumulativeCurrencyTitle);
      commit('SET_DATA', data);
      commit('SET_ITEMS', data.items);
    },

    async loadChart({ getters, commit, state }, request) {
      const response = await http.post('v2/Finance/GetStakingStatsChart ', {
        ...getters.requestData,
        period: 'Day',
        ...request,
        currencyIdList: [state.currentCurrencyId],
      });
      const { data } = response;
      commit('SET_CHART', data.items);
    },
  },

  namespaced: true,
});
