//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    config: { type: Array, default: () => [] },
    className: { type: String, default: '' },
  },

  methods: {
    actionHandler({ action, payload }) {
      this.$emit(action, payload);
    },
  },
};
