//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DropdownList',

  props: {
    config: { type: Array, default: () => [] },
  },

  methods: {
    actionHandler({ action, payload }) {
      if (!action) {
        return;
      }
      this.$emit('action', { action, payload });
    },
  },
};
