//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import FilterControls from '~/components/filters/Controls.vue';

export default {
  components: {
    FilterControls,
  },

  props: {
    value: { type: Object, default: () => ({}) },
    name: { type: String, required: true },
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      defaultFilters: {},
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),

    isUnchanged() {
      const { value, defaultFilters } = this;
      const currentStringify = JSON.stringify(value);
      const defaultStringify = JSON.stringify(defaultFilters);
      return currentStringify === defaultStringify;
    },
  },

  mounted() {
    const { value } = this;
    this.defaultFilters = { ...value };
  },

  methods: {
    resetFilters() {
      const { defaultFilters } = this;
      this.$emit('input', { ...defaultFilters });
    },

    applyFilters(data) {
      this.$emit('input', { ...data });
    },
  },
};
