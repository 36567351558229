//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  props: {
    showSelect: { type: Boolean, default: false },
    showExtra: { type: Boolean, default: false },
    showActions: { type: Boolean, default: false },
    selection: { type: Array, default: () => [] },
    isSelectedAll: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    config: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: false },
    fixedHeader: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    height: { type: String, default: '' },
    perPage: { type: Number, default: 0 },
    toSet: { type: Function, default: (e) => e.id },
    sort: { type: Object, default: () => ({}) },
    className: { type: String, default: '' },
    highlighted: { type: Number, default: null },
    toHighlight: { type: Function, default: (e) => e.id },
    rowStyles: { type: Array, default: null },
  },

  data() {
    return {
      showedExtra: [],
    };
  },

  computed: {
    keys() {
      const { data, toSet } = this;
      return data.map((e) => toSet(e));
    },

    isSelectedAllTable() {
      const { data, selection, toSet } = this;
      if (!selection.length) {
        return false;
      }
      return data.every((e) => {
        const key = toSet(e);
        return selection.includes(key);
      });
    },

    isSelectedAllStyle() {
      const { showExtra } = this;
      const style = {};
      if (showExtra) {
        style.left = '100px';
      } else {
        style.left = '72px';
      }
      return style;
    },

    isSomeExtra() {
      const { showedExtra } = this;
      return showedExtra.length;
    },
  },

  watch: {
    keys(keys, oldKeys) {
      const keysString = JSON.stringify(keys);
      const oldKeysString = JSON.stringify(oldKeys);
      if (keysString !== oldKeysString) {
        this.showedExtra = [];
      }
    },
  },

  methods: {
    ...mapActions('common', ['setSuccessNotification']),

    isExtra(row) {
      const { showedExtra, toSet } = this;
      const key = toSet(row);
      return showedExtra.includes(key);
    },

    toggleExtra(row) {
      this.$emit('rowClick', row);

      const { showExtra } = this;
      if (!showExtra) {
        return;
      }
      const { showedExtra, toSet } = this;
      const key = toSet(row);
      const isShow = showedExtra.includes(key);
      if (isShow) {
        this.showedExtra = showedExtra.filter((e) => e !== key);
      } else {
        this.showedExtra.push(key);
      }
    },

    toggleAllExtra() {
      const {
        isSomeExtra,
        data,
        toSet,
        isLoading,
      } = this;
      if (isLoading) return;

      if (isSomeExtra) {
        this.showedExtra = [];
      } else {
        this.showedExtra = data.map((e) => toSet(e));
      }
    },

    isSelected(row) {
      const { selection, toSet } = this;
      const key = toSet(row);
      return selection.includes(key);
    },

    select(row, value) {
      const { selection, toSet } = this;
      const key = toSet(row);
      const result = value
        ? [...selection, key]
        : selection.filter((e) => e !== key);
      this.$emit('update:selection', result);
    },

    selectAllTable(value) {
      if (value) {
        const { data, toSet } = this;
        const keys = data.map((e) => toSet(e));
        this.$emit('update:selection', keys);
      } else {
        this.$emit('update:selection', []);
      }
    },

    removeSelection() {
      this.$emit('update:selection', []);
      this.$emit('update:isSelectedAll', false);
    },

    isHighlighted(row) {
      const { highlighted, toHighlight } = this;
      if (typeof highlighted === 'number') {
        return highlighted === toHighlight(row);
      }
      return false;
    },

    setSort(sort, sortDesc) {
      this.$emit('sort', { sort, sortDesc });
    },

    getRowStyle(i) {
      const { rowStyles } = this;
      if (!rowStyles) return {};
      return rowStyles[i];
    },

    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.setSuccessNotification('Copied to clipboard');
    },
  },
};
