export default [
  { title: 'Offers and deals', routeName: 'traders-offers-and-deals' },

  { title: 'Transactions', routeName: 'traders-transactions' },

  { title: 'Requisites and devices', routeName: 'traders-requisites-and-devices' },

  { title: 'Fees and rates', routeName: 'traders-fees-and-rates' },

  { title: 'Currencies and payment methods', routeName: 'traders-currencies-and-payment-methods' },
];
