import navigationStatistics from '~/config/navigationStatistics';
import navigationSettings from '~/config/navigationSettings';
import navigationP2P from '~/config/navigationP2P';
import navigationTraders from '~/config/navigationTraders';
import navigationPaymentSystemsAndCurrencies from '~/config/navigationPaymentSystemsAndCurrencies';
import navigationFinance from '~/config/navigationFinance';

const REDIRECT_FALLBACK_ROUTE_NAME = 'account';

const statisticsRouteName = navigationStatistics[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const settingsRouteName = navigationSettings[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const p2pRouteName = navigationP2P[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const tradersRouteName = navigationTraders[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const paymentSystemsAndCurrenciesRouteName = navigationPaymentSystemsAndCurrencies[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;
const financeRouteName = navigationFinance[0]?.routeName || REDIRECT_FALLBACK_ROUTE_NAME;

export default [
  { title: 'Dashboard', routeName: 'dashboard', icon: 'mdi-view-dashboard' },

  { title: 'Users', routeName: 'users', icon: 'mdi-account-multiple' },


  { title: 'Chats', routeName: 'chats', icon: 'mdi-forum' },

  { title: 'Transactions', routeName: 'transactions', icon: 'mdi-swap-horizontal' },


  { title: 'Trading', routeName: 'trading', icon: 'mdi-swap-vertical' },

  { title: 'Markets', routeName: 'markets', icon: 'mdi-currency-usd-circle-outline' },

  { title: 'Payment systems and currencies', routeName: paymentSystemsAndCurrenciesRouteName, icon: 'mdi-credit-card-outline' },


  { title: 'Referral program', routeName: 'referralProgram', icon: 'mdi-account-multiple-plus' },

  { title: 'Finance', routeName: financeRouteName, icon: 'mdi-wallet' },


  { title: 'Statistics', routeName: statisticsRouteName, icon: 'mdi-text-box' },


  { title: 'Settings', routeName: settingsRouteName, icon: 'mdi-cog' },

  { title: 'Admin management', routeName: 'adminManagement', icon: 'mdi-shield-account-variant' },

  { title: 'Account', routeName: 'account', icon: 'mdi-account' },
];
