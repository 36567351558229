import http from '~/http/index';

export default ({
  state: {
    isBannersEnabled: false,
    banners: [],
  },

  getters: {
    isBannersEnabled(state) {
      return state.isBannersEnabled;
    },
    banners(state) {
      return state.banners;
    },
  },

  mutations: {
    SET_IS_BANNERS_ENABLED(state, data) { state.isBannersEnabled = data; },
    SET_BANNERS(state, data) { state.banners = data; },
  },

  actions: {
    async loadBannerOptions({ commit }) {
      const response = await http.get('v2/Options/GetBannerOptions');
      const { isBannersEnabled, banners } = response.data;
      commit('SET_IS_BANNERS_ENABLED', isBannersEnabled);
      commit('SET_BANNERS', banners);
    },

    updateGlobalBannersOptions(_store, value) {
      return http.post('v2/Options/UpdateGlobalBannersOptions', {
        isBannersEnabled: value,
      });
    },

    createBannerOption(_store, data) {
      return http.post('v2/Options/CreateBannerOption', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },

    updateBannerOption(_store, data) {
      return http.post('v2/Options/UpdateBannerOption', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },

    deleteBannerOption(_store, position) {
      return http.post('v2/Options/DeleteBannerOption', null, { params: { position } });
    },
  },

  namespaced: true,
});
