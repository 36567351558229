import moment from 'moment';
import i18n from '~/plugins/i18n';

export const getFullDate = (value) => moment(value).format('DD.MM.YYYY HH:mm');

export const getCaption = (
  list,
  value,
  itemText = (e) => e.caption && i18n.t(`const.${e.caption}`),
  itemValue = (e) => e.id,
) => {
  const item = list.find((e) => itemValue(e) === value);
  if (item) {
    return itemText(item);
  }
  return '';
};

export const isIdentificatorType = (identificatorList, types) => {
  const findByType = identificatorList?.find(({ serviceType }) => {
    return Array.isArray(types) ? types.includes(serviceType) : serviceType === types;
  });
  return findByType ? findByType.identificator : '–';
};


export const updateData = (data, localisationData) => {
  const filterByAlias = (name, array) => {
    return array.filter(({ alias }) => alias === name);
  };

  const modifyP2PData = localisationData.map((item) => {
    return {
      ...item,
      body: item.message,
      notificationServiceType: item.serviceType || 0,
    };
  });
  const removedDuplicates = modifyP2PData
    .map((item, index, array) => {
      return {
        alias: item.alias,
        notificationServiceType: item.notificationServiceType,
        serviceType: item.notificationServiceType,
        isP2P: true,
        templates: filterByAlias(item.alias, array),
      };
    })
    .reduce((acc, current) => {
      const x = acc.find((item) => item.alias === current.alias);
      return !x ? acc.concat([current]) : acc;
    }, []);
  data.push({
    notificationType: 100,
    items: removedDuplicates,
  });
  return data;
};
