import http from '~/http/index';

export default ({
  state: {
    data: [],
    additionalData: {},
    filters: {},
    sort: {
      sort: 'cumulativeBalance',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_ADDITIONAL_DATA(state, data) {
      state.additionalData = data;
    },

    EXPAND_DATA(state, { userId, data }) {
      const expandedData = JSON.parse(JSON.stringify(state.data));
      const item = expandedData.find((e) => e.userId === userId);
      item.balanceInfo = data;
      state.data = expandedData;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({ getters, commit, rootGetters }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const requestData = { ...getters.requestData };
      if (requestData.currencyId || requestData.currencyId === 0) {
        const response = await http.post('Trading/UserFinanceFileterSearch', {
          currencyIdList: [requestData.currencyId],
          limit: requestData.limit,
          sortDesc: requestData.sortDesc,
          offset: requestData.offset,
          getTotal: requestData.getTotal,
          ...data,
        });
        if (response.data) {
          const { userFinanceList, pages } = response.data;
          const currencyTitle = rootGetters['currencies/getCurrencyById']?.(requestData.currencyId)?.title;
          const transformedItems = userFinanceList
            .filter((user) => (user.userName || user.userEmail)) // TODO As long as there are empty users coming from the backend
            .map((userFinance, index) => {
              const { balance, ...finances } = userFinance;
              const desiredCurrencyFinances = balance.find((currency) => currency.currencyId === requestData.currencyId) || {};
              return {
                ...desiredCurrencyFinances,
                ...finances,
                cumulativeBalance: desiredCurrencyFinances.availableBalance,
                id: index,
              };
            });
          commit('SET_DATA', transformedItems);
          commit('SET_ADDITIONAL_DATA', { cumulativeCurrencyTitle: currencyTitle });
          if (pages) {
            commit('SET_PAGES', pages);
          }
        }
      } else {
        const { currencyId: _exclude, ...payload } = requestData;
        const response = await http.post('v2/Trading/GetCumulativeBalances', {
          ...payload,
          ...data,
        });
        const { items, cumulativeCurrencyTitle, total } = response.data;
        const transformedItems = items.map((e, i) => ({
          id: i + 1,
          ...e,
        }));
        commit('SET_DATA', transformedItems);
        commit('SET_ADDITIONAL_DATA', { cumulativeCurrencyTitle });
        if (total) {
          const pages = Math.ceil(total / itemsPerPage);
          commit('SET_PAGES', pages);
        }
      }
    },

    async loadBalanceInfo({ state, commit }, userId) {
      const { data } = await http.post('v2/Trading/GetBalanceInfo', {
        userId,
        cumulativeCurrencyId: state.filters.cumulativeCurrencyId,
      });
      commit('EXPAND_DATA', { userId, data });
    },

    async updateParaminingPerc(__store, data) {
      const response = await http.put('v2/Staking/UpdateUserStaking', data);
      return response.data;
    },
  },

  namespaced: true,
});
