import http from '~/http/index';

export default ({
  state: {
    data: [],
    additionalData: {},
    tradingOptions: {},
    rates: [],
    filters: {},
    sort: {
      sort: 'sortId',
      sortDesc: true,
    },
    page: 1,
    pages: 0,
    filterLists: {
      arrayList: [
        { id: 0, caption: 'Caption' },
      ],
    },
  },

  getters: {
    requestData(state, _getters, _rootState, rootGetters) {
      const { filters, sort, page } = state;
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      return {
        ...filters,
        ...sort,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        getTotal: true,
      };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_SORT(state, data) { state.sort = data; },
    SET_PAGE(state, value) { state.page = value; },
    SET_PAGES(state, value) { state.pages = value; },

    SET_DATA(state, data) {
      state.data = data;
    },

    SET_ADDITIONAL_DATA(state, data) {
      state.additionalData = data;
    },

    SET_TRADING_OPTIONS(state, data) {
      state.tradingOptions = data;
    },

    SET_RATES(state, data) {
      state.rates = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    setSort({ commit }, data) { commit('SET_SORT', data); },
    setPage({ commit }, value) { commit('SET_PAGE', value); },
    setPages({ commit }, value) { commit('SET_PAGES', value); },

    async loadData({
      state,
      getters,
      commit,
      dispatch,
      rootGetters,
    }, data) {
      const itemsPerPage = rootGetters['common/itemsPerPage'];
      const response = await http.post('v2/Market/GetMarkets', {
        ...getters.requestData,
        ...data,
      });
      const {
        items,
        cumulativeCurrencyTitle,
        cumulativeCurrencyId,
        total,
      } = response.data;

      // Здесь мы получаем курсы валют, чтобы конвертировать общие настройки ордеров в базовую
      // валюту рынка
      const commonAmountLimit = items.some((e) => !e.orderAmountLimit);
      if (commonAmountLimit) {
        const ratesItems = items
          .filter((e) => !e.orderAmountLimit)
          .map((e) => ({
            baseCurrencyId: state.tradingOptions.baseCurrencyId,
            derivedCurrencyId: e.baseCurrencyId,
          }))
          .concat({
            baseCurrencyId: state.tradingOptions.baseCurrencyId,
            derivedCurrencyId: state.filters.cumulativeCurrencyId,
          });
        await dispatch('loadRates', { items: ratesItems });
      }

      commit('SET_DATA', items);
      commit('SET_ADDITIONAL_DATA', { cumulativeCurrencyTitle, cumulativeCurrencyId });
      if (total) {
        const pages = Math.ceil(total / itemsPerPage);
        commit('SET_PAGES', pages);
      }
    },

    async createMarket(__store, data) {
      const response = await http.post('v2/Market/CreateMarket', data);
      return response.data;
    },

    async editMarket(__store, data) {
      const response = await http.put('v2/Market/EditMarket', data);
      return response.data;
    },

    async loadTradingOptions({ commit }) {
      const { data } = await http.get('Options/GetTradingOptions');
      commit('SET_TRADING_OPTIONS', data);
    },

    async updateTradingOptions(__store, data) {
      const response = await http.post('Options/UpdateTradingOptions', data);
      return response.data;
    },

    async loadRates({ commit }, data) {
      const response = await http.post('v2/Market/GetRates', data);
      const { items } = response.data;
      commit('SET_RATES', items);
    },
  },

  namespaced: true,
});
