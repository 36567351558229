import moment from 'moment';
import UAParser from 'ua-parser-js';
import store from '../store/index';
import constant from '../const/index';
import { sciToDec } from './scientific-to-decimal';
import { getRouteAccessibility, getSectionAccessibility } from './check-access-rights';
import { getCaption, getFullDate, isIdentificatorType } from './common';

export default {
  getDate: (value) => moment(value).format('DD.MM.YYYY'),

  getTime: (value) => moment(value).format('HH:mm'),

  getParsedUA: (value) => UAParser(value),

  getMarketById: (value) => {
    const { markets } = store.state.currencies;
    const market = markets.find((e) => e.id === value);
    return market;
  },

  getOrderTypeById: (value) => {
    const { ORDER_TYPE_LIST } = constant.orders;
    const orderType = ORDER_TYPE_LIST.find((e) => e.id === value);
    return orderType;
  },

  downloadReport: async (data, file) => {
    const objectUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', file);
    document.body.appendChild(link);
    link.click();
  },
  getFileSize: (length) => {
    if (typeof length !== 'number') return 'unknown';
    if (length < 1024) return `${length} B`;
    if (length < 1048576) return `${(length / 1024).toFixed()} kB`;
    return `${(length / 1048576).toFixed(1)} mB`;
  },

  generatePassword: (from = 8, to = 16) => {
    const length = Math.floor(Math.random() * (to - from)) + from;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
    const charactersLength = characters.length;
    do {
      result = '';
      // eslint-disable-next-line
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
    } while (!constant.users.PASSWORD_REGEXP_SIGNUP.test(result));
    return result;
  },

  sciToDec,
  getRouteAccessibility,
  getSectionAccessibility,
  getCaption,
  getFullDate,
  isIdentificatorType,
};
