import http from '~/http/new';

export default ({
  state: {
    filters: {},
    data: [],
    currencies: [],
    sources: [],
  },

  getters: {
    requestData(state) {
      const { filters } = state;
      const formattedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});
      return { ...formattedFilters };
    },
  },

  mutations: {
    SET_FILTERS(state, data) { state.filters = data; },
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_SOURCES(state, data) {
      state.sources = data;
    },
    SET_CURRENCIES(state, data) {
      state.currencies = data;
    },
  },

  actions: {
    setFilters({ commit }, data) { commit('SET_FILTERS', data); },
    async loadData({ commit, getters }, payload = {}) {
      const response = await http.get('/v1/p2p/rates', {
        params: {
          ...getters.requestData,
          ...payload,
        },
      });
      const { result, items } = response.data;
      if (result?.Success) {
        commit('SET_DATA', items);
      }
    },
    async loadRateCurrencies({ commit }) {
      const response = await http.get('/v1/p2p/currencies/active');
      const { result, items } = response.data;
      if (result?.Success) {
        commit('SET_CURRENCIES', items);
      }
    },
    async loadRateSources({ commit }) {
      const response = await http.get('/v1/p2p/rate/sources');
      const { result, Sources } = response.data;
      if (result?.Success) {
        commit('SET_SOURCES', Sources);
      }
    },

    async editRates(__store, data) {
      const response = await http.put('/v1/p2p/rate', data);
      if (!response?.data?.result?.Success) {
        throw new Error(`The Rates has not been edited because of Error: "${response?.data?.result?.Error}"`);
      }
      return response.data;
    },
  },

  namespaced: true,
});
